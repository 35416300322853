import React, { useEffect } from 'react';
import { useUser } from '../hooks/UserProvider';
import '../css/computador/Home.css';
import mensagensEmblemas from '../mensagens/emblemas';
import Emblema from '../components/Emblema';
import { useInfoHome } from '../context/InfoHomeContext';
import LoadingSpinner from './LoadingSpinner';



const Home = () => {
    const user = useUser();
    const infoHome = useInfoHome();
    // Verifica se o usuário está autenticado
    if (user === 401) {
      window.location.href = '/login';
    }
   
    if (!user) {
        return <div><LoadingSpinner mensagem="Carregando perfil..."></LoadingSpinner></div>;
    }
    if (!infoHome.info) {
        return <div><LoadingSpinner mensagem="Carregando perfil..."></LoadingSpinner></div>;
    }
   

    return (
        <div className='container-home'>
            <div className='container-header'>
                <div className='boas-vindas'>
                <h1>Bem-vindo de volta,<br></br> {user.name}</h1>
                <p>Volte à prática ou explore novos conteúdos</p>
                </div>
                <div className='resumo-container'>
                  
                    <div>
                        <h2>Questões respondidas</h2>
                        <p>{infoHome.info.questao_resolvida_count}</p>
                    </div>
                    <div>
                        <h2>Questões corretas</h2>
                        <p>{infoHome.info.questao_correta_count}</p>
                    </div>
                    <div>
                        <h2>Redações</h2>
                        <p>{infoHome.info.redacao_count}</p>
                    </div>
                    <div>
                        <h2>Comentários</h2>
                        <p>{infoHome.info.comentario_count}</p>
                    </div>

                    <div  className="center-item"> 
                        <h2>Ranking</h2>
                        <p>0</p>
                    </div>
                </div>
                <div>

                </div>
              
            </div>
            <div className='container-main'>
                <div className='nivel-progesso'>
                    <div className='progresso-intro'>
                        <h1>Seu nível</h1>
                        <img src={mensagensEmblemas[infoHome.info.emblema].src}></img>
                        <h2>{mensagensEmblemas[infoHome.info.emblema].nome}</h2>
                        <p>{mensagensEmblemas[infoHome.info.emblema].descricao} </p>
                    </div>
                   
                </div>
                <div className='other-informations'>
                    
                    <div>
                        <h1>Emblemas</h1>
                    </div>
                    <div >
                       <Emblema></Emblema>
                       </div>
                </div>
            </div>

           
        </div>
    );
    }

export default Home;