const disciplinas = [
    'Português',
        'Espanhol',
        'Inglês',
        'Química',
        'Biologia',
        'História',
        'Conhecimentos Gerais',
        'Geografia',
        'Literatura',
        'Física',
        'Matemática',
        'Raciocínio Lógico',
        'História e Geografia de Estados e Municípios',
        'Ciências',
        'Atualidades',
        'Francês',
        'Astronomia',
        'Educação Artística',
        'Sociologia',
        'Meio Ambiente',
        'Filosofia',
        'Engenharia Química e Química Industrial',
        'Artes Visuais',
        'Ciência Política',
        'Farmácia',
        'Música',
        'Estatística',
        'Geologia',
        'Arquivologia',
        'Biblioteconomia',
        'Legislação de Trânsito',
        'Direito Urbanístico',
        'Programação',
        'Artes Cênicas',
        'Artes Plásticas',
        'Nutrição',
        'Gastronomia',
        'Enfermagem',
        'Economia',
        'Saúde Pública',
        'Engenharia Mecânica',
        'Italiano',
        'Alemão',
        'Medicina',
        'Antropologia',
        'Não definido',
        'Educação Física',
        'Mecânica',
        'Metrologia',
        'Banco de Dados',
        'Metalurgia',
        'Redes de Computadores',
        'Eletrônica',
        'Edificações',
        'Engenharia Agronômica (Agronomia)',
        'Direito Processual Penal Militar',
        'Direito Constitucional',
        'Direito Administrativo',
        'Direito Eleitoral',
        'Legislação Estadual',
        'Noções de Informática',
        'Redação Oficial',
        'Segurança da Informação',
        'Mecânica de Autos',
        'Segurança e Saúde no Trabalho',
        'Direito Sanitário',
        'Sistemas Operacionais',
        'Governança de TI',
        'Gerência de Projetos',
        'Administração Geral',
        'Direito Processual Penal',
        'Direito Penal Militar',
        'Direito Penal',
        'Direito Previdenciário',
        'Direito Processual Civil - CPC 1973',
        'Direito Civil',
        'Psicologia',
        'Odontologia',
        'Arquitetura de Software',
        'Matemática Financeira',
        'Contabilidade Geral',
        'Contabilidade Pública',
        'Legislação Federal',
        'Noções de Primeiros Socorros',
        'Fisioterapia',
        'Psiquiatria',
        'Relações Internacionais',
        'Segurança e Transporte',
        'Engenharia Civil',
        'Técnicas em Laboratório',
        'Biomedicina - Análises Clínicas',
        'Auditoria de Obras Públicas',
        'Pedagogia',
        'Engenharia Eletrônica',
        'Engenharia Elétrica',
        'Engenharia Cartográfica',
        'Atendimento ao Público',
        'Administração Pública',
        'Gestão de Pessoas',
        'Engenharia de Telecomunicações',
        'Engenharia de Agrimensura',
        'Análise de Balanços',
        'Administração de Recursos Materiais',
        'Teologia',
        'Administração Financeira e Orçamentária',
        'Radiologia',
        'Contabilidade de Custos',
        'Veterinária',
        'Engenharia de Produção',
        'Engenharia Aeronáutica',
        'Direitos Humanos',
        'Segurança Pública',
        'Direito da Criança e do Adolescente - Estatuto da Criança e do Adolescente (ECA) - Lei nº 8.069 de 1990',
        'Ética na Administração Pública',
        'Direito Internacional Público',
        'Telecomunicações',
        'Arquitetura de Computadores',
        'Serviço Social',
        'Criminologia',
        'Patologia',
        'Ciências Navais',
        'Engenharia de Automação',
        'Algoritmos e Estrutura de Dados',
        'Meteorologia',
        'Engenharia de Software'
];

export default disciplinas;