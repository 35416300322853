import Select2 from "./Select2";
import { useEffect, useState } from "react";
import '../css/computador/FiltroQuestoes.css';
import bancas from "../dados_intra/bancas";
import assuntos from "../dados_intra/assuntos";
import disciplinas from "../dados_intra/disciplinas";
import { useLocation, useNavigate } from "react-router-dom";

const degenerationObject = (objList) => {
    let newList = [];
    for (let i = 0; i < objList.length; i++) {
        newList.push(objList[i].value);
    }
    return newList;
}

const useQuery = () => {    
    return new URLSearchParams(useLocation().search);
}

const FiltroQuestoes = ({navigate}) => {
    const [procuraQuestao, setProcuraQuestao] = useState('');
    const dic = disciplinas.map(el => ({ value: el, label: el}))
    const instituicao = bancas.map(el => ({ value: el, label: el}))
    const assunto = assuntos.map(el => ({ value: el, label: el}))

    const query = useQuery();
    const [selectedAssunto, setSelectedAssunto] = useState([]);
    useEffect(() => {
        const disciplinas = query.get('disciplinas');
        const instituicoes = query.get('instituicoes');
        const anos = query.get('anos');
        const nivel = query.get('nivel');
        const enunciado = query.get('enunciado');
        
        const assuntos = query.get('assuntos');
        console.log(enunciado)

        if (disciplinas) {
            setSelectedDisciplina(disciplinas.split(',').map(el => ({ value: el, label: el})));
        }

        if (instituicoes) {
            setSelectedInstituicao(instituicoes.split(',').map(el => ({ value: el, label: el})));
        }

        if (anos) {
            setSelectedAno(anos.split(',').map(el => ({ value: el, label: el})));
        }

        if (nivel) {
            setSelectedNivel(nivel.split(',').map(el => ({ value: el, label: el})));
        }

        if (enunciado) {
            setProcuraQuestao(enunciado);
        }

        if (assuntos) {
            setSelectedAssunto(assuntos.split(',').map(el => ({ value: el, label: el})));
        }
    }, []);

    const anos = [
        { value: 'todas', label: 'Todos' }, 
        { value: '2021', label: '2021' },
        { value: '2020', label: '2020' },
        { value: '2019', label: '2019' },
        { value: '2018', label: '2018' },
        { value: '2017', label: '2017' },
        { value: '2016', label: '2016' },
        { value: '2015', label: '2015' },
        { value: '2014', label: '2014' },
        { value: '2013', label: '2013' },
        { value: '2012', label: '2012' },
        { value: '2011', label: '2011' },
        { value: '2023', label: '2023' },

    ]

    const [selectedDisciplina, setSelectedDisciplina] = useState([]);
    const [selectedInstituicao, setSelectedInstituicao] = useState([]);
    const [selectedAno, setSelectedAno] = useState([]);
 
    const [selectedNivel, setSelectedNivel] = useState([]);
    const handleChange = selectedOption => {
        setSelectedDisciplina(selectedOption);
      };
    
    const handleChangeInstituicao = selectedOption => {
        setSelectedInstituicao(selectedOption);
      }

    const nivel = [
        { value: 'todas', label: 'Todos'},
        { value: 'Fácil', label: 'Fácil' },
        { value: 'Médio', label: 'Médio' },
        { value: 'Difícil', label: 'Difícil' },
        { value: 'Muito Difícil', label: 'Muito Difícil'}
    ]

    const handleProcuraQuestao = (e) => {
        e.preventDefault();
        setProcuraQuestao(e.target.value);
    }

    const handleChangeAno = selectedOption => {
        setSelectedAno(selectedOption);
    }

    const handleChanceNivel = selectedOption => {
        setSelectedNivel(selectedOption);
    }

    const handleChangeAssunto = selectedOption => {
        setSelectedAssunto(selectedOption);
    }

    const useBuscar = () => {
        window.location.href = '/questoes?disciplinas=' + degenerationObject(selectedDisciplina) + '&instituicoes=' + degenerationObject(selectedInstituicao) + '&anos=' + degenerationObject(selectedAno) + '&nivel=' + degenerationObject(selectedNivel) + '&enunciado=' + procuraQuestao + '&assuntos=' + degenerationObject(selectedAssunto);
    }

    return (
        <div className="container-questoes">
            <div>
                <h1>Buscar questões</h1>
                <div className="container-questoes-select">
                    <Select2 label="Selecione a disciplina" placeholder="Selecione a disciplina"  isMulti={true}  onChange={handleChange} options={dic} value={selectedDisciplina} />
                    <Select2 label="Selecione o assunto" placeholder="Selecione o assunto" isMulti = {true} options={assunto} onChange={handleChangeAssunto} value={selectedAssunto} />
                    <Select2 label="Selecione a instituição" placeholder="Selecione a instituição" isMulti={true}  options={instituicao} onChange={handleChangeInstituicao}  value={selectedInstituicao} />
                    <Select2 label="Selecione o ano" placeholder="Selecione o ano" isMulti={true}   options={anos} onChange={handleChangeAno} value = {selectedAno} />
                    <Select2 label="Selecione o nível" placeholder="Selecione o nível" isMulti={true}  options={nivel} onChange={handleChanceNivel} value={selectedNivel} />
                </div>
                

            </div>
            <div className="container-excluir">
                <div>
                <p style={{color:'gray'}}>Exluir questões: </p>
                </div>
                <div className="container-labels">
                    <label  htmlFor="questoes-respondidas">Questões respondidas</label>

                    <input defaultChecked name="questoes-respondidas" id = "questoes-respondidas" type="checkbox"/>
                    <label htmlFor="questoes-corretas">Questões corretas</label>
                    <input defaultChecked name ="questoes-corretas" id ="questoes-corretas" type="checkbox"></input>
                    <label htmlFor="simulados">Dos meus simulados</label>
                    <input defaultChecked name="simulados" id="simulados" type="checkbox"></input>
                </div>
                    
                    
                </div>
            <div className="container-questoes-select">
                
                <input value={procuraQuestao} onChange={handleProcuraQuestao}  type="text" placeholder="Digite o enunciado da questão"></input>
                
                <button onClick={useBuscar}>Buscar Questões</button>
                

            </div>
            
        </div>
    );
}

export default FiltroQuestoes;