import FiltroSimulados from "../components/FiltroSimulados";
import { useState } from "react";
import { IoCut } from "react-icons/io5";
import '../css/computador/Simulado.css';
import LoadingSpinner from "./LoadingSpinner";

const Simulados = () => {
    const [avancado, setAvancado] = useState(false);
    const [simulado, setSimulado] = useState({ simulado: [], instrucoes_prova: '' });
    const [gerando, setGerando] = useState(false);
    // const [gabaritoAluno, setGabaritoAluno] = useState({});
    const [chute, setChute] = useState(false);

    // Armazena a alternativa selecionada para cada questão
    const [gabaritoAluno, setGabaritoAluno] = useState({});
    // Armazena se cada alternativa está cortada para cada questão
    const [cortadas, setCortadas] = useState({});
    console.log(gabaritoAluno)
    const handleOpcao = (opcao, numQuestao) => {
        setGabaritoAluno(prev => ({ ...prev, [numQuestao]: opcao }));
        setGabaritoAluno(prev => ({ ...prev, [numQuestao]: opcao }));
    };

    const handleMarcarOpcao = (opcao, numQuestao) => {
        setGabaritoAluno(prev => ({ ...prev, [numQuestao]: opcao }));
        handleOpcao(opcao, numQuestao);
    };

    const handleCortarAlternativa = (alternativa, numQuestao) => {
        setCortadas(prev => ({
            ...prev,
            [numQuestao]: {
                ...prev[numQuestao],
                [alternativa]: !prev[numQuestao]?.[alternativa]
            }
        }));
    };
    console.log(gabaritoAluno)

   
        

    const correcao = () => {
        let questoes = simulado.simulado;
        let questoes_branco = []
        for (let i = 0; i < questoes.length; i++) {
            
            if(gabaritoAluno[i] == undefined){
                questoes_branco.push(i + 1)
            } 
            
        }
        if (questoes_branco.length > 0){
                
            let resp = window.confirm(`Você não respondeu as questões ${questoes_branco}. Deseja continuar?`)
            if (!resp){
                return
        }}
        console.log(simulado.vestibular)
        if(simulado.vestibular === 'ITA'){
            let acertos_matematica = 0;
            let acertos_fisica = 0;
            let acertos_quimica = 0;
            let acertos_portugues = 0;
            let acertos_ingles = 0;
            let questoes = simulado.simulado;

            let questoes_branco =[]

            for (let i = 0; i < questoes.length; i++) {
                if(gabaritoAluno[i] == questoes[i].gabarito){
                    if (i <12){
                        acertos_fisica++
                }
                else if (i < 24){
                    acertos_portugues++
                }
                else if (i < 36){
                    acertos_ingles++
                }
                else if (i < 48){
                    acertos_matematica++
                }
                else {
                    acertos_quimica++
                }}
                
                
            }
            alert(`Você acertou ${acertos_fisica} questões de Física, ${acertos_portugues} questões de Português, ${acertos_ingles} questões de Inglês, ${acertos_matematica} questões de Matemática e ${acertos_quimica} questões de Química.`)
        }

            else{

                if(simulado.simulado.materia == 'Português'){
                    let acertos_linguagens = 0;
                    let acertos_humanas = 0;
        
                    let erros_linguagens = 0;
                    let erros_humanas = 0;
        
                    let brancos_linguagens = 0;
                    let brancos_humanas = 0;
        
                    let questoes = simulado.simulado;
        
                    let questoes_branco_linguagens =[]
                    let questoes_branco_humanas =[]
                    for (let i = 0; i < questoes.length; i++) {
                        
                        if(gabaritoAluno[i] == questoes[i].gabarito){
                            if (i < 45){
                                acertos_linguagens++
                            } else {
                                acertos_humanas++
                            }
                        } else if (gabaritoAluno[i] == undefined){
                            if (i < 45){
                                questoes_branco_linguagens.push(i + 1)
                            } else {
                                questoes_branco_humanas.push(i + 1)
                            }
                        } else {
                            if (i < 45){
                                erros_linguagens++
                            } else {
                                erros_humanas++
                            }
                        }
                        
                    }
        
                    alert(`Você acertou ${acertos_linguagens} questões de Linguagens e ${acertos_humanas} questões de Ciências Humanas. Você errou ${erros_linguagens} questões de Linguagens e ${erros_humanas} questões de Ciências Humanas. Você deixou em branco ${brancos_linguagens} questões de Linguagens e ${brancos_humanas} questões de Ciências Humanas.`)
                }
                else{
                    let acertos_matematica = 0;
                    let acertos_natureza = 0;
                    let erros_matematica = 0;
                    let erros_natureza = 0;
                    let brancos_matematica = 0;
                    let brancos_natureza = 0;
        
                    let questoes = simulado.simulado;
                    let questoes_branco_matematica =[]
                    let questoes_branco_natureza =[]
                    for (let i = 0; i < questoes.length; i++) {
                        console.log(gabaritoAluno[i])
                        if(gabaritoAluno[i] == questoes[i].gabarito){
                            if (i < 45){
                                acertos_matematica++
                            } else {
                                acertos_natureza++
                            }
                        } else if (gabaritoAluno[i] === undefined){
                            if (i < 45){
                                brancos_matematica++
                            } else {
                                brancos_natureza++
                            }
                        }
                        else {
                            if (i < 45){
                                erros_matematica++
                            } else {
                                erros_natureza++
                            }
                        }
        
                    }
                    alert(`Você acertou ${acertos_matematica} questões de Matemática e ${acertos_natureza} questões de Ciências da Natureza. Você errou ${erros_matematica} questões de Matemática e ${erros_natureza} questões de Ciências da Natureza. Você deixou em branco ${brancos_matematica} questões de Matemática e ${brancos_natureza} questões de Ciências da Natureza.`)  
            }
               return 
            
            }

            

            
        
       }
    
    return (
        <div className="simulado-container-out">
            <FiltroSimulados setGabaritoAluno={setGabaritoAluno} setSimulado={setSimulado} setAvancado={setAvancado} avancado={avancado} setGerando={setGerando} />

            <div className="simulado-container">
                {gerando && <LoadingSpinner mensagem="Gerando simulado..." />}

                {simulado.simulado.length > 0 && !gerando && simulado.vestibular == 'ENEM' &&  (
                    <div dangerouslySetInnerHTML={{ __html: `${simulado.instrucoes_prova.toString()}` }} />
                )}
                 {simulado.simulado.length > 0 && !gerando && simulado.vestibular == 'ITA' &&  (
                    <>
                    <div className="container-ita">   
                            <h1>{simulado.instrucoes_prova.titulo}</h1>
                            <img style={{margin:"auto"}} src={simulado.instrucoes_prova.url_img}></img>
                            <h2 style={{color:'#ccc', margin:"auto"}} >Simulado VestibaQuest</h2>

                    </div>
                    
                    <ol className="ol-simu">
                    {
                        simulado.instrucoes_prova.instrucoes.map((instrucao, index) => {
                            return (
                                <li key={index}>{instrucao}</li>
                            );
                           
                        })
                    }
                    </ol>
                  
                    </>
                    
                    )
                }
                {simulado.simulado.length > 0 && !gerando && simulado.simulado.map((questao, index) => {
                    const alternativasSelecionadas = gabaritoAluno[index] || '';
                    const alternativasCortadas = cortadas[index] || {};

                    return (
                        <div key={index} >
                            <span className="num-questoes">{index + 1}</span>
                            <div className='enunciado' dangerouslySetInnerHTML={{ __html: `${questao.enunciado.toString()}` }} />
                            <div className='alternativas-container'>
                                {questao.alternativa_a && (
                                    <div className='alternativa-a-container'>
                                        <div>
                                            <IoCut onClick={() => handleCortarAlternativa('A', index)} />
                                        </div>
                                        <div className={`container-alternativa ${chute && questao.gabarito === 'A' ? 'correta' : ''} ${alternativasCortadas['A'] ? 'cortar' : ''}`}>
                                            <div value="A" onClick={() => handleMarcarOpcao('A', index)} className={`alternativa ${alternativasSelecionadas === 'A' ? 'opcao_marcada' : ''}`}>A</div>
                                            <div className={`alternativa-a-contianer`} dangerouslySetInnerHTML={{ __html: `${questao.alternativa_a.toString()}` }} />
                                        </div>
                                    </div>
                                )}
                                {questao.alternativa_b && (
                                    <div className='alternativa-b-container'>
                                        <div>
                                            <IoCut onClick={() => handleCortarAlternativa('B', index)} />
                                        </div>
                                        <div className={`container-alternativa ${chute && questao.gabarito === 'B' ? 'correta' : ''} ${alternativasCortadas['B'] ? 'cortar' : ''}`}>
                                            <div value="B" onClick={() => handleMarcarOpcao('B', index)} className={`alternativa ${alternativasSelecionadas === 'B' ? 'opcao_marcada' : ''}`}>B</div>
                                            <div className={`alternativa-b-contianer`} dangerouslySetInnerHTML={{ __html: `${questao.alternativa_b.toString()}` }} />
                                        </div>
                                    </div>
                                )}
                                {questao.alternativa_c && questao.alternativa_c !== "None" && (
                                    <div className='alternativa-c-container'>
                                        <div>
                                            <IoCut onClick={() => handleCortarAlternativa('C', index)} />
                                        </div>
                                        <div className={`container-alternativa ${chute && questao.gabarito === 'C' ? 'correta' : ''} ${alternativasCortadas['C'] ? 'cortar' : ''}`}>
                                            <div value="C" onClick={() => handleMarcarOpcao('C', index)} className={`alternativa ${alternativasSelecionadas === 'C' ? 'opcao_marcada' : ''}`}>C</div>
                                            <div className={`alternativa-c-contianer`} dangerouslySetInnerHTML={{ __html: `${questao.alternativa_c.toString()}` }} />
                                        </div>
                                    </div>
                                )}
                                {questao.alternativa_d && questao.alternativa_d !== "None" && (
                                    <div className='alternativa-d-container'>
                                        <div>
                                            <IoCut onClick={() => handleCortarAlternativa('D', index)} />
                                        </div>
                                        <div className={`container-alternativa ${chute && questao.gabarito === 'D' ? 'correta' : ''} ${alternativasCortadas['D'] ? 'cortar' : ''}`}>
                                            <div value="D" onClick={() => handleMarcarOpcao('D', index)} className={`alternativa ${alternativasSelecionadas === 'D' ? 'opcao_marcada' : ''}`}>D</div>
                                            <div className={`alternativa-d-contianer`} dangerouslySetInnerHTML={{ __html: `${questao.alternativa_d.toString()}` }} />
                                        </div>
                                    </div>
                                )}
                                {questao.alternativa_e && questao.alternativa_e !== "None" && (
                                    <div className='alternativa-e-container'>
                                        <div>
                                            <IoCut onClick={() => handleCortarAlternativa('E', index)} />
                                        </div>
                                        <div className={`container-alternativa ${chute && questao.gabarito === 'E' ? 'correta' : ''} ${alternativasCortadas['E'] ? 'cortar' : ''}`}>
                                            <div value="E" onClick={() => handleMarcarOpcao('E', index)} className={`alternativa ${alternativasSelecionadas === 'E' ? 'opcao_marcada' : ''}`}>E</div>
                                            <div className={`alternativa-e-contianer`} dangerouslySetInnerHTML={{ __html: `${questao.alternativa_e.toString()}` }} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}

                {
                    simulado.simulado.length > 0 && !gerando && (
                        
                        <button className="correcao-btn" onClick={() => correcao()}>Terminar</button>
                    )
                }
            </div>
        </div>
    );
};

export default Simulados;
