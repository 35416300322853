import React from 'react';

const Paginacao = ({ pagination, onPageChange }) => {
  const { page, totalPages } = pagination;

  const handleNext = () => {
    if (page < totalPages) {
      onPageChange(page + 1);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  return (
    <div className="paginacao">
      <button onClick={handlePrev} disabled={page <= 1}>Anterior</button>
      <span>{page} de {totalPages}</span>
      <button onClick={handleNext} disabled={page >= totalPages}>Próxima</button>
    </div>
  );
};

export default Paginacao;
