import React from 'react';
import '../css/computador/EmblemaCard.css';

const EmblemaCard = ({ emblema, onClose }) => {
    return (
        <div className='emblema-card'>
            <div className='emblema-card-content'>
                <img src={emblema.src} alt={emblema.nome} className='emblema-card-image' />
                <h2>{emblema.nome}</h2>
                <p>{emblema.descricao}</p>
                <button onClick={onClose}>Fechar</button>
            </div>
        </div>
    );
};

export default EmblemaCard;
