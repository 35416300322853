import React, { useState } from 'react';
import Select from 'react-select';

const Select2 = ({ onChange, value, label, options, isMulti, placeholder }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  return (
    <div className='select'>
      <label>{label}</label>
      <Select
        options={options}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        isSearchable
        isMulti={isMulti}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        menuIsOpen={menuIsOpen}
        openMenuOnFocus={true}
      />
    </div>
  );
};

export default Select2;
