const bancas = ["todas","CEDERJ",
"Inglesa",
"FAME",
"FATEC",
"FGV",
"FMP",
"FURG",
"FUVEST",
"MG",
"IF-BA",
"IF-MT",
"IF-PE",
"IF-PR",
"IF-RR",
"IF-SE",
"IF-SP",
"IF-TM",
"IFF",
"IFN-MG",
"INSPER",
"Campinas",
"PR",
"RJ",
"RS",
"SP",
"PUC-GO",
"PUC-MINAS",
"SENAC-SP",
"UCB",
"UCS",
"UDESC",
"UEA",
"UECE",
"UEG",
"UEL",
"UEMG",
"UEPB",
"UERJ",
"UESPI",
"UFAC",
"UFAL",
"UFBA",
"UFCG",
"UFF",
"UFG",
"UFGD",
"UFLA",
"UFMG",
"UFMS",
"UFMT",
"UFPR",
"UFRGS",
"UFRN",
"UFSCAR",
"UFT",
"UFTM",
"UFU-MG",
"UNB",
"UNCISAL",
"UNEAL",
"UNEMAT",
"UNESP",
"UNESPAR",
"UNICAMP",
"UNICENTRO",
"UniCEUB",
"UNIFAL-MG",
"UNIFESP",
"UNIOESTE",
"UNIR",
"URCA",
"USP",
"AFA",
"APMBB",
"BM-RS",
"CAP",
"CBM-AC",
"CBM-AL",
"CBM-AP",
"CBM-BA",
"CBM-CE",
"CBM-DF",
"CBM-ES",
"CBM-GO",
"CBM-MA",
"CBM-MG",
"CBM-MS",
"CBM-MT",
"CBM-PA",
"CBM-PB",
"CBM-PE",
"CBM-PI",
"CBM-PR",
"CBM-RJ",
"CBM-RN",
"CBM-RO",
"CBM-SC",
"CBM-SE",
"CBM-TO",
"CFN",
"CIAAR",
"CMB",
"CMBel",
"CMBH",
"CMC",
"CMCG",
"CMF",
"CMJF",
"CMM",
"CMPA",
"CMR",
"CMRJ",
"CMS",
"CMSM",
"CMSP",
"COLÉGIO NAVAL",
"Comando do 1º Distrito Naval",
"Comando do 2º Distrito Naval",
"Comando do 3º Distrito Naval",
"Comando do 4º Distrito Naval",
"Comando do 5º Distrito Naval",
"Comando do 6º Distrito Naval",
"Comando do 7º Distrito Naval",
"Comando do 8º Distrito Naval",
"Comando do 9º Distrito Naval",
"CP-PCNS",
"EAM",
"EEAR",
"EFOMM",
"EPCAR",
"ESCOLA NAVAL",
"EsFCEx",
"EsPCEx",
"EsSA",
"ETAM",
"FAB",
"HFA",
"IME",
"ITA",
"MARINHA",
"MDS",
"PM-AC",
"PM-AL",
"PM-AM",
"PM-AP",
"PM-BA",
"PM-CE",
"PM-DF",
"PM-ES",
"PM-GO",
"PM-MA",
"PM-MG",
"PM-MS",
"PM-MT",
"PM-PA",
"PM-PB",
"PM-PE",
"PM-PI",
"PM-PR",
"PM-RJ",
"PM-RN",
"PM-RO",
"PM-RR",
"PM-RS",
"PM-SC",
"PM-SE",
"PM-SP",
"PM-TO",
"Polícia Federal",
"Quadro Complementar",
"Quadro Técnico",
"TRT - 16ª REGIÃO (MA)",
"ENEM"
]

export default bancas;