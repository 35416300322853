import React, { useState, useEffect, useRef } from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import FiltroQuestoes from '../components/FiltroQuestoes';
import { AiFillLike, AiFillDislike } from 'react-icons/ai';
import { FaHourglassEnd } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { VscDebugContinue } from 'react-icons/vsc';
import { IoCut } from "react-icons/io5";
import { CiStop1, CiPause1 } from "react-icons/ci";
import '../css/computador/Questoes.css';
import { useLocation , useNavigate} from 'react-router-dom';
import axios from 'axios';
import host from '../constantes';
import Emblema from '../components/Emblema';
import mensagensEmblemas from '../mensagens/emblemasNovoNivel';
import EmblemaCard from '../components/EmblemaCard';
import Paginacao from '../components/Paginacao'; // Componente de paginação que criaremos a seguir

import { useUser } from '../hooks/UserProvider';
import assuntos from '../dados_intra/assuntos';
import LoadingSpinner from './LoadingSpinner';
const Questao = ({ questao, stopAllTimers, setPassouNivel,  setSelectedEmblema, limpar,setLimpar }) => {
  
   

  // const fetchDataFromExternalResource = async () => {
  //   const token = await getToken();
  //   console.log(token)
  // }

  const user = useUser();

  // const mathJaxRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(true);
  const [timeLeft, setTimeLeft] = useState(300);
  const [running, setRunning] = useState(false);
  const timerRef = useRef(null);
  const handleTime = () => {
    setShowTooltip(false);
    setRunning(true);

    timerRef.current = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(timerRef.current);
    setRunning(false);
  };

 

  const sendRespCorrect = async (id) => {
    if (user == 401 || user == null) {
      return;
    }
    const response = await fetch(host + "api/v1/resposta-correta/" + id, {
      credentials: 'include',
      origin: 'https://vestibaquest.com.br',
      headers: {
          'Content-Type': 'application/json',
          'origin': 'https://vestibaquest.com.br',
      },
    })

    if(response.status == 200){
      // console.log('resposta correta')

    }
    if (response.status == 400) {
      return;
    }
    const data = await response.json();

    if(data['novo-nivel'] != 'nao'){
      setSelectedEmblema(data['novo-nivel']);
      setPassouNivel(true);
    }

  }


  const sendRespIncorrect = async (id) => {
    if (user == 401 || user == null) {
      return;
    }

    const response = await fetch(host + "api/v1/resposta-incorreta/" + id, {
      credentials: 'include',
      origin: 'https://vestibaquest.com.br',
      headers: {
          'Content-Type': 'application/json',
          'origin': 'https://vestibaquest.com.br',
      },
    })

    if(response.status == 200){
      console.log('resposta incorreta')
    }
    const data = await response.json();
  
    
   

  }

  

  const handleVerificaResposta = (e) => {
    e.preventDefault()
    setChute(resposta)
    const certo = questao.gabarito
    if(resposta ==certo){
      sendRespCorrect(questao.id)
    }
    else{
      sendRespIncorrect(questao.id)
    }

   

    
  }

  const handleStop = () => {
    clearInterval(timerRef.current);
    setTimeLeft(300);
    setRunning(false);
    setShowTooltip(true);
  };

  useEffect(() => {
    if (timeLeft === 0) {
      clearInterval(timerRef.current);
      setRunning(false);
      setShowTooltip(true);
    }
  }, [timeLeft]);

  // useEffect(() => {
  //   if (mathJaxRef.current ) {
     
      
  //     // Atualiza o MathJax
  //     window.MathJax.typesetPromise();
  //   }
  // }, [questao]);
  
  useEffect(() => {
    if (stopAllTimers) {
      handleStop();
    }
  }, [stopAllTimers]);

  const handleTempoEsgotado = () => {
    setShowTooltip(true);
    setTimeLeft(300);
    clearInterval(timerRef.current);
    setRunning(false);
  };

  const [alternativaA, setAlternativaA] = useState(false)
  const [alternativaB, setAlternativaB] = useState(false)
  const [alternativaC, setAlternativaC] = useState(false)
  const [alternativaD, setAlternativaD] = useState(false)
  const [alternativaE, setAlternativaE] = useState(false)
  const [opcaoAmarcada, setOpcaoAmarcada] = useState(false)
  const [opcaoBmarcada, setOpcaoBmarcada] = useState(false)
  const [opcaoCmarcada, setOpcaoCmarcada] = useState(false)
  const [opcaoDmarcada, setOpcaoDmarcada] = useState(false)
  const [opcaoEmarcada, setOpcaoEmarcada] = useState(false)
  const [chute, setChute] = useState(false)
  const [resposta, setResposta] = useState('')
  
  // if(limpar){
  //   setOpcaoAmarcada(false)
  //   setOpcaoBmarcada(false)
  //   setOpcaoCmarcada(false)
  //   setOpcaoDmarcada(false)
  //   setOpcaoEmarcada(false)

  //   setLimpar(false)
  // }




 
  const handleMarcarOpcao = (value) => {
   
    setOpcaoAmarcada(value ==='A')
    setOpcaoBmarcada(value ==='B')
    setOpcaoCmarcada(value ==='C')
    setOpcaoDmarcada(value ==='D')
    setOpcaoEmarcada(value ==='E')

    if (value === 'A') {
      setResposta('A')
    } else if (value === 'B') {
      setResposta('B')
    }
    else if (value === 'C') {
      setResposta('C')
    }
    else if (value === 'D') {
      setResposta('D')
    }
    else if (value === 'E') {
      setResposta('E')
    }



  }
  if (questao == "Nenhuma questão encontrada") {
    return  <div className='sem-questoes'>
    Nenhuma questão encontrada
  </div>
  }

  return (
    // <MathJaxContext >
    <div  className='container-questao'>
      
      <div>
            <div className='container-header-q'>
              <span>
                <strong>Disciplina: </strong>
                {questao.materia}
              </span>
              <span>
                <strong>Vestibular: </strong>
                {questao.vestibular}
              </span>
              <span>
                <strong>Ano: </strong>
                {questao.ano}
              </span>
            </div>
            <div className='relogio'>
        {showTooltip && (
          <>
            <FaHourglassEnd
              onClick={handleTime}
              data-tooltip-id="hourglassTip"
              data-tooltip-content="Para passar nos vestibulares, não basta apenas resolver uma questão, você tem que ser rápido suficiente para conseguir resolver o máximo de questões no tempo da prova. Nada melhor que se preparar para esse quesito simulando a questão com tempo. Em média, o tempo ideal pra resolver uma questão é menos de 5 minutos. Ao clicar em mim, um contador irá aparecer para que você possa treinar o tempo de resolução."
              className="fa-hourglass-end"
            />
            <Tooltip
              className='tooltip'
              id="hourglassTip"
              place="top"
              effect="solid"
              event="hover"
              eventOff="mouseleave">
              Para passar nos vestibulares, não basta apenas resolver uma questão, você tem que ser rápido suficiente para conseguir resolver o máximo de questões no tempo da prova. Nada melhor que se preparar para esse quesito simulando a questão com tempo. Em média, o tempo ideal pra resolver uma questão é menos de 5 minutos. Ao clicar em mim, um contador irá aparecer para que você possa treinar o tempo de resolução.
            </Tooltip>
          </>
        )}

        {!showTooltip && running && (
          <>
            <div className='pause'>
              <CiPause1 onClick={handlePause} />
            </div>
          </>
        )}

        {!showTooltip && !running && (
          <div className='continue'>
            <VscDebugContinue onClick={handleTime} />
          </div>
        )}

        {!showTooltip && (
          <div className='pause'>
            <CiStop1 onClick={handleStop} />
          </div>
        )}

        <div className={showTooltip ? `` : `timer`} id='timer'>
          {!showTooltip && (
            <>
              {timeLeft > 0 ? `${timeLeft}s` : handleTempoEsgotado()}
            </>
          )}
        </div>
      </div>
            <div className='enunciado'  dangerouslySetInnerHTML={{ __html: `${questao.enunciado.toString()}` }} />
  
          <div className='alternativas-container'>
            {
              questao.alternativa_a != undefined && (
                <div  className='alternativa-a-container'>
                  <div>
                  < IoCut onClick={e => setAlternativaA(!alternativaA)} />
                  </div>
                  
                  <div className={`container-alternativa ${chute && 'A'==  questao.gabarito  ? 'correta': '' } ${alternativaA ? 'cortar': ''}`}>
                    <div value="A" onClick={e => handleMarcarOpcao(e.target.getAttribute("value"))} className={`alternativa ${opcaoAmarcada ? 'opcao_marcada': ''}` }>A</div>
                  <div  className={`alternativa-a-contianer `  }   dangerouslySetInnerHTML={{ __html: `${questao.alternativa_a.toString()}` }} />
                    </div>
                </div>
              )
              
            }

            {
              questao.alternativa_b != undefined && (
                <div className='alternativa-b-container'>
                  <div>
                  <IoCut onClick={e => setAlternativaB(!alternativaB)}  />
                  </div>
                
                  <div className={`container-alternativa ${chute && 'B' ==  questao.gabarito ? 'correta': '' } ${alternativaB ? 'cortar' : ''}`}>
                    <div value="B" onClick={e => handleMarcarOpcao(e.target.getAttribute("value"))} className={`alternativa ${opcaoBmarcada ? 'opcao_marcada': ''}` } >B</div>
                    <div className={`alternativa-b-contianer `}   dangerouslySetInnerHTML={{ __html: `${questao.alternativa_b.toString()}` }} />
                  </div>
                </div>
              )
            }

            {
              questao.alternativa_c != undefined && questao.alternativa_c != "None" && (
                <div className='alternativa-c-container'>
                  <div>
                  <IoCut   onClick={e => setAlternativaC(!alternativaC)}  />
                  </div>
                
                  <div className={`container-alternativa ${chute && 'C' ==  questao.gabarito  ? 'correta': '' } ${alternativaC ? 'cortar' : ''}`}>
                    <div value="C" onClick={e => handleMarcarOpcao(e.target.getAttribute("value"))} className={`alternativa ${opcaoCmarcada ? 'opcao_marcada': ''}` }>C</div>
                  <div className={`alternativa-c-contianer`}   dangerouslySetInnerHTML={{ __html: `${questao.alternativa_c.toString()}` }} />
                  </div>  
                </div>
              )
            }

            {
              questao.alternativa_d != undefined && questao.alternativa_d != "None" &&(
                <div className='alternativa-d-container'>
                  <div>
                  <IoCut onClick={e => setAlternativaD(!alternativaD)}  />
                  </div>
                
                    <div className={`container-alternativa ${chute && 'D' ==  questao.gabarito  ? 'correta': '' } ${alternativaD ? 'cortar' : ''}`}>
                      <div value="D" onClick={(e) => handleMarcarOpcao(e.target.getAttribute("value"))} className={`alternativa ${opcaoDmarcada ? 'opcao_marcada': ''}` }>D</div>
                    <div className={`alternativa-d-contianer`}    dangerouslySetInnerHTML={{ __html: `${questao.alternativa_d.toString()}` }} />
                    </div>
                </div>
              )
            }

            {
              questao.alternativa_e != undefined && questao.alternativa_e != "None" &&(
                <div className='alternativa-e-container'>
                  <div>
                  <IoCut onClick={e => setAlternativaE(!alternativaE)}  />
                  </div>
                
                  <div className={`container-alternativa ${chute && 'E'  ==  questao.gabarito ? 'correta': '' } ${alternativaE ? 'cortar' : ''}`}>
                    <div  value="E" onClick={e => handleMarcarOpcao(e.target.getAttribute("value"))} className={`alternativa ${opcaoEmarcada ? 'opcao_marcada': ''}` }>E</div>

                    <div className={`alternativa-e-contianer`}   dangerouslySetInnerHTML={{ __html: `${questao.alternativa_e.toString()}` }} />
                    </div>
                </div>
              ) 
            }


          </div>
       
      </div>
      <div className='header-questao'>
        <div className='like'>
          <div className='icon-like'><AiFillLike /></div>
          <div className='icon-deslike'><AiFillDislike /> </div>
          <div className='container-comentarios'>Comentarios</div>
        </div>
        <div className='answer-questao'>
          {
            questao.gabarito == chute && (
              <div className='resposta-correta'>Resposta correta</div>
            )
             
          }
          {
            questao.gabarito != chute && chute != false && (
              <div className='resposta-incorreta'>Resposta incorreta</div>
            )
          }

          {
            !chute  && (
              <div className='pd-10 font-bold hover:text-yellow-700'>
                  <button onClick={handleVerificaResposta} className='btn-questao'>Responder</button>
                </div>
            
            )
          }
         
        </div>
      </div>
    </div>
  // </MathJaxContext>   
  );

};
const constructQuery = (disciplinas, anos, nivel, procuraQuestao, instituicoes, enunciado,assuntos, page, page_size) => {
  let query = '';
  if (disciplinas.length > 0) {
    query += `disciplinas=${disciplinas.join(',')}&`;
  }
  if (anos.length > 0) {
    query += `anos=${anos.join(',')}&`;
  }
  if (nivel.length > 0) {
    query += `nivel=${nivel.join(',')}&`;
  }
  if (procuraQuestao) {
    query += `procuraQuestao=${procuraQuestao}&`;
  }
  if (instituicoes.length > 0) {
    query += `instituicoes=${instituicoes.join(',')}&`;
  }
  if (enunciado) {
    query += `enunciado=${enunciado}&`;
  }
  if (assuntos){
    query += `assuntos=${assuntos.join(',')}&`;
  }
  if (page) {
    query += `page=${page}&`;
  }
  if (page_size) {
    query += `page_size=${page_size}&`;
  }
  return query;
}

const useQuery = () => {    
  return new URLSearchParams(useLocation().search);
}


const Questoes = () => {
  const [questoes, setQuestoes] = useState([]);
  const [stopAllTimers, setStopAllTimers] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10, totalCount: 0, totalPages: 0 });
  const [passouNivel, setPassouNivel] = useState(false);
  const [selectedEmblema, setSelectedEmblema] = useState(null);
  const mathJaxRef = useRef(null);
  const navigate = useNavigate();
  const use = useQuery();

  useEffect(() => {
    setStopAllTimers(true);
    const timer = setTimeout(() => setStopAllTimers(false), 100);
    return () => clearTimeout(timer);
  }, [questoes]);

  const handleaPass = async () => {
    const disciplinas  = use.get('disciplinas') ? use.get('disciplinas').split(',') : [];
    const anos = use.get('anos') ? use.get('anos').split(',') : [];
    const nivel = use.get('nivel') ? use.get('nivel').split(',') : [];
    const procuraQuestao = use.get('procuraQuestao');
    const instituicoes = use.get('instituicoes') ? use.get('instituicoes').split(',') : [];
    const enunciado = use.get('enunciado') ? use.get('enunciado') : '';
    const page = use.get('page') || pagination.page;
    const pageSize = use.get('page_size') || pagination.pageSize;
    const assuntos = use.get('assuntos') ? use.get('assuntos').split(',') : [];
    const query = constructQuery(disciplinas, anos, nivel, procuraQuestao, instituicoes, enunciado, assuntos,page, pageSize);
  

    const response = await fetch(host + `api/v1/questoes?${query}`, {
      credentials: 'include',
      origin: 'http://vestibaquest.com.br',
      headers: {
          'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    setPagination({
      page: data.pagination.page,
      pageSize: data.pagination.page_size,
      totalCount: data.pagination.total_count,
      totalPages: data.pagination.total_pages,
    });
    if (data.questoes.length == 0) {
      setQuestoes(["Nenhuma questão encontrada"]);
    }else{
      for(let i = 0; i < data.questoes.length; i++){
        if(data.questoes[i].alternativa_d == "None"){
          if(data.questoes[i].gabarito == "C"){
            data.questoes[i].gabarito = "A"
          }else{
            data.questoes[i].gabarito = "B"
          }
        }
      }
      setQuestoes(data.questoes);
    }
    
  };

 
  const query = useQuery();

  const disciplinas = query.get('disciplinas') == undefined ? '' : query.get('disciplinas');
    const instituicoes = query.get('instituicoes') == undefined ? '' : query.get('instituicoes');
    const anos = query.get('anos') == undefined ? '' : query.get('anos');
    const nivel = query.get('nivel') == undefined ? '' : query.get('nivel');
    const enunciado = query.get('enunciado') == undefined ? '' : query.get('enunciado');
    const assuntos = query.get('assuntos') == undefined ? '' : query.get('assuntos');
    const [limpar, setLimpar] = useState(false)

  const handlePageChange = (newPage) => {
    
    setLimpar(true)
    // navigate(`/questoes?disciplinas=${disciplinas}&instituicoes=${instituicoes}&anos=${anos}&nivel=${nivel}&enunciado=${enunciado}&page=${newPage}`);
    // window.scrollTo({ top: 0, behavior: 'smooth' });

    // era do jeito acima, mas esse navigate nao estava atualizando a parte das questoes, as alternativas continuavam marcadas porque acho que como usa o navigate ele nao atualiza a pagina toda, entao usei o window.location.href que atualiza a pagina toda
    window.location.href =  `/questoes?disciplinas=${disciplinas}&instituicoes=${instituicoes}&anos=${anos}&nivel=${nivel}&enunciado=${enunciado}&assuntos=${assuntos}&page=${newPage}`;
    
  };

  useEffect(() => {
    handleaPass();
  }, [useLocation().search]);

  useEffect(() => {
    if (mathJaxRef.current && questoes.length > 0) {
      // Atualiza o MathJax
      window.MathJax.typesetPromise();
    }
  }, [questoes]);

  const handleClose = () => {
    setSelectedEmblema(null);
    setPassouNivel(false);
  };

  if (questoes == ["Nenhuma questão encontrada"]) {
    return (
      <div className='container-home'>
        <FiltroQuestoes navigate={navigate} />
        <div className='sem-questoes'>
          Nenhuma questão encontrada
        </div>
      </div>
    );
  }
  console.log(questoes != "Nenhuma questão encontrada")
  return (
    <div className='container-home'>
      <FiltroQuestoes navigate={navigate} />
      {passouNivel && <EmblemaCard emblema={mensagensEmblemas[selectedEmblema]} onClose={handleClose} />}
      <MathJaxContext>
        <div ref={mathJaxRef}>
          {questoes.length > 0 && questoes.map((questao, index) => (
            <Questao
              setPassouNivel={setPassouNivel}
              setSelectedEmblema={setSelectedEmblema}
              questao={questao}
              key={index}
              stopAllTimers={stopAllTimers}
              limpar={limpar}
              setLimpar={setLimpar}
            />
          ))}
        </div>
      </MathJaxContext>
      {
        questoes.length > 0 && questoes != "Nenhuma questão encontrada" &&(
          <div className='pagination'>
            <Paginacao pagination={pagination} onPageChange={handlePageChange} />
          </div>
        )
        
      }
     
      {
        questoes.length == 0 && (
          <LoadingSpinner mensagem="Carregando questões..."></LoadingSpinner>
        )
      }
   
     
    </div>
  );
};

export default Questoes;