import React, { useState } from 'react';
import { useUser } from '../hooks/UserProvider';
import '../css/computador/PerfilToggle.css';
import { IoMdClose } from "react-icons/io";
import { GoSignOut } from "react-icons/go";
import host from '../constantes';
const PerfilToggle = () => {
  const user = useUser();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = (e) => {
    e.preventDefault();
    fetch(host + 'api/v1/logout', {
      origin: 'https://vestibaquest.com.br', 
      
      method: 'GET',
      credentials: 'include',

    }).then(() => {
      window.location.href = '/login';
    });
  };

  if (!user) {
    return null; // Ou um spinner/loading
  }
 
  return (
    <div className="perfil-toggle">
      <img
        src={user.picture}
        alt="Profile"
        className="perfil-toggle-pic"
        onClick={() => setIsOpen(!isOpen)}
      />
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className='container-header'>
          <div className='header'>
            <div className='header-info'>
              <img src={user.picture} alt="Profile" />
              <h1>{user.name}</h1>
            </div>
            <div className='close'>
              <IoMdClose onClick={() => setIsOpen(!isOpen)} />
            </div>
          </div>
        </div>
        <div className="perfil-toggle-menu">
            <GoSignOut />
          <p onClick={handleLogout}>Sair</p>
        </div>
      </div>
    </div>
  );
};

export default PerfilToggle;
