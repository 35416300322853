import React, { useState } from 'react';
import '../css/computador/Emblema.css';
import novato from '../imagens/novato.svg';
import aprendiz from '../imagens/aprendiz.svg';
import intermediario from '../imagens/intermediario.svg';
import avancado from '../imagens/avancado.svg';
import mestre from '../imagens/mestre.svg';
import expert from '../imagens/expert.svg';
import genio from '../imagens/genio.svg';
import lenda from '../imagens/lenda.svg';
import virtuoso from '../imagens/virtuoso.svg';
import EmblemaCard from './EmblemaCard';
import grandmaster from '../imagens/grandmaster.svg';

const emblemas = [
    { src: novato, nome: 'Novato', descricao: 'Cada grande conquista começa com o primeiro passo. Acredite no seu potencial e dê o seu melhor, um dia de cada vez.' },
    { src: aprendiz, nome: 'Aprendiz', descricao: 'Você está começando a dominar os conceitos básicos. Continue assim! Resolva 10 questões.' },
    { src: intermediario, nome: 'Intermediário', descricao: 'Você está no caminho certo, alcançando novos níveis de conhecimento. Resolva 20 questões.' },
    { src: avancado, nome: 'Avançado', descricao: 'Você está quase lá! Continue se esforçando e atingirá a maestria. Resolva 400 questões.' },
    { src: mestre, nome: 'Mestre', descricao: 'Parabéns, você alcançou um nível de excelência impressionante. Resolva 700 questões e mande 4 resoluções.' },
    { src: expert, nome: 'Expert', descricao: 'Seu conhecimento é profundo e vasto. Você é uma referência. Resolva 1100 questões e mande 20 resoluções.' },
    { src: genio, nome: 'Gênio', descricao: 'Você é um prodígio em sua área de estudo. Continue brilhando! Resolsa 2500 questões e mande 40  resoluções.' },
    { src: lenda, nome: 'Lenda', descricao: 'Seu nome será lembrado por gerações. Você alcançou o status de lenda. Resolva 4000 questões e ajude uma pessoa corrigindo a redação dele' },
    { src: virtuoso, nome: 'Virtuoso', descricao: 'Seu talento é inigualável. Você é um exemplo de dedicação e habilidade. Resolva 4500 questões e ajude alguém corrigindo 3 redações.' },
    { src: grandmaster, nome: 'Grandmaster', descricao: 'Você está no ápice. Seu domínio é absoluto. Resolva 5000 questões, ajude 20 pessoas com resolução de questão e corrija 10 redações.' },
];

const Emblema = () => {
    const [selectedEmblema, setSelectedEmblema] = useState(null);

    const handleClick = (emblema) => {
        setSelectedEmblema(emblema);
    };

    const handleClose = () => {
        setSelectedEmblema(null);
    };

    return (
        <div className='emblema-container'>
            <div className='emblemas'>
                {emblemas.map((emblema, index) => (
                    <img 
                        key={index} 
                        src={emblema.src} 
                        alt={emblema.nome} 
                        className='emblema' 
                        onClick={() => handleClick(emblema)} 
                    />
                ))}
            </div>
            {selectedEmblema && <EmblemaCard emblema={selectedEmblema} onClose={handleClose} />}
        </div>
    );
};

export default Emblema;
