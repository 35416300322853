import React from 'react';
import '../css/computador/LandingPage.css';
import feature1 from '../imagens/feature1.jpg';
import feature2 from '../imagens/feature2.jpg';
import feature3 from '../imagens/feature3.jpg';
import feature4 from '../imagens/feature4.jpg';
import vestibular from '../imagens/vestibular.jpg';
import vestibaquest from '../imagens/vestibaquest.jpg';
import Sidebar from '../components/SideBar';

function LandingPage() {
  return (
    <div className='landing-page'>
       
      <MainSection />
      <Features />
      <Footer />
    </div>
  );
}



const MainSection = () => (
  <section className="main-section">
   <div className="container">
    <h2>Prepare-se para o <img className='vestibular-home' src={vestibular} ></img> com <img className='vestibular-home' src={vestibaquest}></img>. É 100% grátis!</h2>
    <p>Mais de 150 mil questões para você estudar e passar no vestibular.</p>
    <a className="cta-button" href="/login">Comece Agora</a>
</div>

  </section>
);

const Features = () => (
  <section className="features" id="features">
    <div className="container">
      <h2>O que você vai encontrar</h2>
      <div className="features-grid">
        <div className="feature-item">
          <img src={feature1} alt="Questões" />
          <h3>Questões</h3>
          <p>Milhares de questões para você praticar.</p>
        </div>
        <div className="feature-item">
          <img src={feature2} alt="Ranking" />
          <h3>Ranking</h3>
          <p>Veja seu desempenho em relação aos outros.</p>
        </div>
        <div className="feature-item">
          <img src={feature3} alt="Simulados" />
          <h3>Simulados</h3>
          <p>Teste seus conhecimentos com simulados.</p>
        </div>
        <div className="feature-item">
          <img src={feature4} alt="Relatórios" />
          <h3>Relatórios</h3>
          <p>Receba relatórios semanais do seu progresso.</p>
        </div>
      </div>
    </div>
  </section>
);

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <p>&copy; 2024 VestibaQuest. Todos os direitos reservados.</p>
    </div>
  </footer>
);

export default LandingPage;
