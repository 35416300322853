const RelatorioDesempenho = () => {
    return (
        <div style={{margin:"auto", display:"flex", flexDirection:"column",alignContent:"center", alignItems:"center", justifyContent:"center", height:"45vh"}}>
        <h1>Em breve...</h1>
            
        <h2>Acesse a página de <a style={{textDecoration:"none"}} href="/questoes" >questões</a> </h2>
        </div>
    );
    }

export default RelatorioDesempenho;