import './App.css';
import { BrowserRouter as Router, Route, Routes,  Link } from 'react-router-dom';
import Home from './pages/Home';
import Questoes from './pages/Questoes';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import Sidebar from './components/SideBar';
// import AuthCallback from './pages/AuthCallback';
import UserProfile from './pages/UserProfile';
import {useUser} from './hooks/UserProvider';
import PerfilToggle from './components/PerfilToggle';
import { InfoHomeProvider } from './context/InfoHomeContext'
import { UserProvider } from './hooks/UserProvider';
import Redacoes from './pages/Redacoes';
import Simulados from './pages/Simulados';
import Ranking from './pages/Ranking';
import RelatorioDesempenho from './pages/RelatorioDesempenho';
import SobreMim from './pages/SobreSite';
import LandingPage from './pages/LandingPage';
import './App.css'
function App() {
  const user = useUser();
  console.log(user)

  return (
    <>
    
    <UserProvider>
    <InfoHomeProvider>
      <div  className='container-app'>
      <div className='sidebar-app'>
            <Sidebar></Sidebar>
      </div>
      
      {
        user != 401 && <div className='perfil'>
        <div>
          {user && <PerfilToggle></PerfilToggle>}
        </div>
    </div>
      }
      
      <div className='container-body'>
      <Router>
              <Routes>
                <Route path='/questoes' element={<Questoes></Questoes>} ></Route>
                {
                  (user === 401 || user === null || user === undefined) &&  <Route path='/' element={<LandingPage></LandingPage>} ></Route>
                }
               {
                  user != 401 && <Route path='/' element={<Home></Home>} ></Route>
               }
               
                {
                  (user === 401 || user === null || user === undefined) && <Route path='/login' element={<Login></Login>} ></Route>
                }
                <Route path='/user' element={<UserProfile></UserProfile>} ></Route>
                <Route path='/redacoes' element={<Redacoes></Redacoes>} ></Route>
                <Route path='/simulados' element={<Simulados></Simulados>} ></Route>
                <Route path='/ranking' element={<Ranking></Ranking>} ></Route>
                <Route path='/relatorio-desempenho' element={<RelatorioDesempenho></RelatorioDesempenho>} ></Route>
                <Route path="/sobre-o-site" element = {<SobreMim/>}></Route>
           
                <Route path='*' element={<Home />}/>
              </Routes>
      </Router>
      </div>
      </div>
    </InfoHomeProvider>
    </UserProvider>
     
    </>
  );
}

export default App;