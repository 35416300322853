import Select2 from "./Select2";
import { useEffect, useState } from "react";
import '../css/computador/FiltroSimulados.css';
import bancas from "../dados_intra/bancas";
import disciplinas from "../dados_intra/disciplinas";
import host  from '../constantes.js';
import { useLocation, useNavigate } from "react-router-dom";

const degenerationObject = (objList) => {
    let newList = [];
    for (let i = 0; i < objList.length; i++) {
        newList.push(objList[i].value);
    }
    return newList;
}

const useQuery = () => {    
    return new URLSearchParams(useLocation().search);
}

const FiltroSimulados = ({navigate,setAvancado, avancado, setSimulado, setGerando, setGabaritoAluno}) => {
    const [procuraQuestao, setProcuraQuestao] = useState('');
    const dic = disciplinas.map(el => ({ value: el, label: el}))
    const instituicao = [{value: 'ENEM', label: 'ENEM'}, {value:"ITA", label:"ITA"}]
    const query = useQuery();

    useEffect(() => {
        const disciplinas = query.get('disciplinas');
        const instituicoes = query.get('instituicoes');
        const anos = query.get('anos');
        const nivel = query.get('nivel');
        const enunciado = query.get('enunciado');
        console.log(enunciado)

        if (disciplinas) {
            setSelectedDisciplina(disciplinas.split(',').map(el => ({ value: el, label: el})));
        }

        if (instituicoes) {
            setSelectedInstituicao(instituicoes.split(',').map(el => ({ value: el, label: el})));
        }

        if (anos) {
            setSelectedAno(anos.split(',').map(el => ({ value: el, label: el})));
        }

        if (nivel) {
            setSelectedNivel(nivel.split(',').map(el => ({ value: el, label: el})));
        }

        if (enunciado) {
            setProcuraQuestao(enunciado);
        }
    }, []);

    const anos = [
        { value: 'todas', label: 'Todos' }, 
        { value: '2021', label: '2021' },
        { value: '2020', label: '2020' },
        { value: '2019', label: '2019' },
        { value: '2018', label: '2018' },
        { value: '2017', label: '2017' },
        { value: '2016', label: '2016' },
        { value: '2015', label: '2015' },
        { value: '2014', label: '2014' },
        { value: '2013', label: '2013' },
        { value: '2012', label: '2012' },
        { value: '2011', label: '2011' },
        { value: '2023', label: '2023' },

    ]

    const [selectedDisciplina, setSelectedDisciplina] = useState([]);
    const [selectedInstituicao, setSelectedInstituicao] = useState({ value: 'ENEM', label: 'ENEM'});
    const [selectedAno, setSelectedAno] = useState([]);
    const [selectedNivel, setSelectedNivel] = useState({ value: 'normal', label: 'Normal'});
    const [selectedDia, setSelectedDia] = useState({value: 'dia-1', label: '1º dia'});
    const [selectedLinguaEstrangeira, setSelectedLinguaEstrangeira] = useState({ value: 'ingles', label: 'Inglês' });
    const handleChange = selectedOption => {
        setSelectedDisciplina(selectedOption);
      };
    
    const handleChangeInstituicao = selectedOption => {
        setSelectedInstituicao(selectedOption);
      }

    const nivel = [
        { value: 'Fácil', label: 'Fácil' },
        { value: 'normal', label: 'Normal'},
        { value: 'Médio', label: 'Médio' },
        { value: 'Dificil', label: 'Difícil' },
     
    ]

    const lingua_estrangeira = [
        { value: 'ingles', label: 'Inglês' },
        { value: 'espanhol', label: 'Espanhol' },
    ]

    const dia_enem = [
        {value: 'dia-1', label: '1º dia'},
        {value: 'dia-2', label: '2º dia'},
    ]
    const dia_ita = [
        {value: 'dia-1', label: '1º dia'},
       
    ]

    const handleProcuraQuestao = (e) => {
        e.preventDefault();
        setProcuraQuestao(e.target.value);
    }

    const handleChangeAno = selectedOption => {
        setSelectedAno(selectedOption);
    }

    const handleChanceNivel = selectedOption => {
        setSelectedNivel(selectedOption);
    }

    const handleChangeDia = selectedOption => {
        setSelectedDia(selectedOption);
    }
    
    const handleLinguaEstrangeira = selectedOption => {
        setSelectedLinguaEstrangeira(selectedOption);
    }
    const useBuscar = () => {
        // window.location.href = '/questoes?disciplinas=' + degenerationObject(selectedDisciplina) + '&instituicoes=' + degenerationObject(selectedInstituicao) + '&anos=' + degenerationObject(selectedAno) + '&nivel=' + degenerationObject(selectedNivel) + '&enunciado=' + procuraQuestao;
        // window.location.href = '/simulados?' + '&instituicoes=' + degenerationObject(selectedInstituicao)  + '&nivel=' + degenerationObject(selectedNivel) + '&dia=' + selectedDia.value;
        setGabaritoAluno([])
    
       
        const fetchSimulado = async () => {
            setGerando(true);
            const response = await fetch(host + 'api/v1/simulado', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    vestibular: selectedInstituicao.value,
                    dificuldade: selectedNivel.value,
                    dia: selectedDia.value,
                    lingua_estrangeira: selectedLinguaEstrangeira.value
                })
            });
            const data = await response.json();
            setSimulado(data);
            setGerando(false);
        }

        fetchSimulado();
    }

    return (
        <div className="container-questoes">
             <h1>Gerador de simulados</h1>
            {/* <div className="avancado">
                <label htmlFor="avancado">avançado</label>
                <input name="avancado" id="avancado" type="checkbox" ></input>
            </div> */}
            <div>
               
                <div className="container-questoes-select">
                   
                    <Select2 label="Selecione a instituição" isMulti={false}  options={instituicao} onChange={handleChangeInstituicao}  value={selectedInstituicao} />
                    <Select2 label="Selecione o nível" placeholder="Nível do simulado" isMulti={false}  options={nivel} onChange={handleChanceNivel} value={selectedNivel} />
                    {
                        selectedInstituicao.value === 'ENEM' && <Select2 label="Selecione o dia" placeholder="Dia" isMulti={false}  options={dia_enem} onChange={handleChangeDia} value={selectedDia} />
                    }
                    {
                        selectedInstituicao.value === 'ENEM' &&  <Select2 label="Selecione o dia" placeholder="Dia" isMulti={false}  options={dia_ita} onChange={handleChangeDia} value={selectedDia} />
                    }
                   
                    {
                        selectedInstituicao.value === 'ENEM' && <Select2 label="Língua estrangeira" placeholder="Língua estrangeira" isMulti={false}  options={lingua_estrangeira} onChange={handleLinguaEstrangeira} value={selectedLinguaEstrangeira} />

                    }
                    {/* {
                        !avancado &&  <Select2 label="Selecione a disciplina" isMulti={true}  onChange={handleChange} options={dic} value={selectedDisciplina} />
                    } */}
                   
                </div>
                

            </div>
            <div className="container-excluir">
                <div>
                <p style={{color:'gray'}}>Exluir questões: </p>
                </div>
                <div className="container-labels-simulados">
                    <label  htmlFor="questoes-respondidas">Questões respondidas</label>
                    <input defaultChecked name="questoes-respondidas" id = "questoes-respondidas" type="checkbox"/>
                    <label htmlFor="questoes-corretas">Questões corretas</label>
                    <input defaultChecked name ="questoes-corretas" id ="questoes-corretas" type="checkbox"></input>
                    <label htmlFor="simulados">Dos simulados anteriores</label>
                    <input defaultChecked name="simulados" id="simulados" type="checkbox"></input>
                    {/* <label onClick={e => setAvancado(!avancado)} htmlFor="avancado">avançado</label>
                    <input name="avancado" id="avancado" type="checkbox" ></input> */}
                </div>
                    
                    
                </div>
            <div className="container-questoes-select">
                
               
                <button onClick={useBuscar}>Gerar simulado</button>
                

            </div>
            
        </div>
    );
}

export default FiltroSimulados;