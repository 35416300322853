import React, { createContext, useContext, useState, useEffect } from 'react';
import host from '../constantes';
const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
 
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        if (host === 'http://localhost:5001/') {
          return;
      }
        const response = await fetch(host + 'api/v1/userprofile', {
          origin: 'https://vestibaquest.com.br',
          credentials: 'include',
        });
        
       if(response.status === 401){
        setUser(401);
       }
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};
