import React, { useState } from 'react';
import { useUser } from '../hooks/UserProvider';
import '../css/computador/Sidebar.css';
import { IoMdClose } from "react-icons/io";
import { GoSignOut } from "react-icons/go";
import { FaStream } from "react-icons/fa";
import { AiOutlineTrophy } from "react-icons/ai";
import { IoBookOutline } from "react-icons/io5";
import { CiBookmark } from "react-icons/ci";
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { BsFileEarmarkPost } from "react-icons/bs";
import { AiOutlineAliwangwang } from "react-icons/ai";
import host from '../constantes';
const Sidebar = () => {
    const user = useUser();
    const [isOpen, setIsOpen] = useState(false);
    const [openedByHover, setOpenedByHover] = useState(false);
  
    const handleLogout = (e) => {
      e.preventDefault();
      fetch(host + 'logout', {
        method: 'GET',
        credentials: 'include',
      }).then(() => {
        window.location.href = '/';
      });
    };
  
    if (!user) {
      return null; // Ou um spinner/loading
    }
  
    const handleBtnFaStream = () => {
      setIsOpen(!isOpen);
      setOpenedByHover(false); // Reset the hover state when manually toggled
    };
  
    const handleIconHover = () => {
      if (!isOpen) {
        setIsOpen(true);
        setOpenedByHover(true);
      }
    };
  
    const handleMouseLeave = () => {
      if (openedByHover) {
        setIsOpen(false);
      }
    };
  
    return (
      <div className="sidebar" onMouseLeave={handleMouseLeave}>
        <div className="sidebar-icons">
          <div className="icon-1">
            <FaStream className="sidebar-icon" style={{ marginBottom: "20px" , marginBottom:"0px !important"}} onClick={handleBtnFaStream} />
          </div>
            <div className="other-icons">
                <div className='other-icons-1'>
                <IoHomeOutline onMouseEnter={handleIconHover} style={{ width: "20px" }} className="sidebar-icon" />
                </div>
                <div className='other-icons-1'>
                <AiOutlineTrophy onMouseEnter={handleIconHover} style={{ width: "20px", marginBottom:"30px" }} className="sidebar-icon" />

                </div>
                <div className='other-icons-1'>
                <IoBookOutline onMouseEnter={handleIconHover} style={{ width: "20px" }} className="sidebar-icon" />

                </div>
                <div className='other-icons-1'>
                <CiBookmark onMouseEnter={handleIconHover} style={{ width: "20px" }} className="sidebar-icon" />

                </div>

                <div className='other-icons-1'>
                <MdOutlineLibraryBooks onMouseEnter={handleIconHover} style={{ width: "20px" }} className="sidebar-icon" />
                </div>

                <div className='other-icons-1'>
                <BsFileEarmarkPost onMouseEnter={handleIconHover} style={{ width: "20px" }} className="sidebar-icon" />
                </div>

                <div className='other-icons-1'>
                <AiOutlineAliwangwang onMouseEnter={handleIconHover} style={{ width: "20px" }} className="sidebar-icon" />
                </div>
          </div>
        </div>
        <div className={` sidebar-intro ${isOpen ? 'open' : ''}`}>
          <div className="sidebar-menu">
            <div className="menu-close">
              <FaStream className="sidebar-icon" style={{ marginBottom: "20px" }} onClick={() => setIsOpen(!isOpen)} />
              <h1>VestibaQuest</h1>
            </div>
            <p> <a href='/'>< IoHomeOutline /> Home</a></p>
            <p> <a href='/ranking'><AiOutlineTrophy/>Ranking</a> </p>
            <p> <a href='/simulados'><IoBookOutline/>Simulados</a> </p>
            <p> <a href='/questoes'><CiBookmark/> Questões</a> </p>
            <p><a href='/redacoes'><MdOutlineLibraryBooks></MdOutlineLibraryBooks>Redações</a></p>
            <p><a href='/relatorio-desempenho' > <BsFileEarmarkPost></BsFileEarmarkPost> Relatório de desempenho </a></p>
            <p><a href='sobre-o-site'><AiOutlineAliwangwang />Sobre o site</a></p>
          </div>
        </div>
      </div>
    );
  };
  
  export default Sidebar;