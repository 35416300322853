const assuntos = [
    "Morfologia",
"Conjunções: Relação de causa e consequência",
"Interpretação de Textos",
"Noções Gerais de Compreensão e Interpretação de Texto",
"Problemas da língua culta",
"Por que- porque/ porquê/ por quê",
"Interpretação de Texto | Comprensión de Lectura",
"Coesão e coerência",
"Sintaxe",
"Concordância verbal Concordância nominal",
"Formação das Palavras: Composição Derivação Hibridismo Onomatopeia e Abreviação",
"Interpretação de texto | Reading comprehension",
"Funções da Linguagem: emotiva apelativa referencial metalinguística fática e poética.",
"Morfologia - Pronomes",
"Pronome de tratamento",
"Tipologia Textual",
"Uso dos conectivos",
"Pontuação",
"Uso dos dois-pontos",
"Representação das transformações químicas",
"Grandezas: massa volume mol massa molar constante de Avogadro e Estequiometria.",
"Moléculas células e tecidos",
"Respiração celular e fermentação",
"Hereditariedade e diversidade da vida",
"Identidade dos seres vivos",
"Sistema Circulatório Humano",
"Vocabulário | Vocabulary",
"Funções morfossintáticas da palavra COMO",
"Figuras de Linguagem",
"Denotação e Conotação",
"Preposições",
"Artigos",
"Pronomes demonstrativos",
"Uso do ponto e vírgula",
"Gêneros Textuais",
"Sinônimos | Synonyms",
"História do Brasil",
"Era Vargas – 1930-1954",
"República de 1954 a 1964",
"Sociedade e Comportamento",
"População",
"População brasileira",
"Agropecuária",
"Questão Fundiária",
"Urbanização",
"Hierarquia urbana",
"Geografia Política",
"Brasil Monárquico – Segundo Reinado 1831- 1889",
"Meio Ambiente na Geografia",
"Impactos e soluções nos meios natural e rural",
"Fontes de energia e recursos naturais",
"Energia",
"Significação Contextual de Palavras e Expressões. Sinônimos e Antônimos.",
"Vocativo e Termos Acessórios da Oração: Adjunto Adnominal Diferença entre Adjunto Adnominal e Complemento Nominal Adjunto Adverbial e Aposto",
"Orações subordinadas adverbiais: Causal Comparativa Consecutiva Concessiva Condicional...",
"Migrações",
"Urbanização brasileira",
"Crase",
"Modernismo",
"Parnasianismo",
"Escolas Literárias",
"Morfologia - Verbos",
"Flexão verbal de tempo (presente pretérito futuro)",
"Física Térmica - Termologia",
"Calorimetria",
"Soluções e Substâncias Inorgânicas",
"Soluções: características tipos de concentração diluição mistura titulação e soluções coloidais.",
"Sistema Endócrino Humano",
"Advérbios e Locuções Adverbiais | Adverbios y Locuciones Adverbiales",
"Análise sintática",
"Vocabulário | Vocabulario",
"Significação Contextual de Palavras e Expressões | Significacción Contextual de Palabras y Expresiones",
"Orações coordenadas sindéticas: Aditivas Adversativas Alternativas Conclusivas...",
"Uso da Vírgula",
"Variação Linguística",
"Flexão verbal de número (singular plural)",
"Flexão verbal de pessoa (1ª 2ª 3ª pessoa)",
"Termos essenciais da oração: Sujeito e Predicado",
"Termos integrantes da oração: Objeto direto Objeto indireto Complemento nominal Agente da Passiva",
"Adjetivos",
"Pronomes possessivos",
"Tipos de Discurso: Direto Indireto e Indireto Livre",
"Dilatações",
"Óptica Geométrica",
"Ótica",
"Eletricidade",
"Resistores e Potência Elétrica",
"Globalização",
"Advérbios e conjunções | Adverbs and conjunctions",
"Pronomes | Pronouns",
"Pronome subjetivo | Subjective pronoun",
"Adjetivos | Adjectives",
"Comparativo e superlativo de adjetivos | Comparative and superlative",
"Flexão verbal de modo (indicativo subjuntivo imperativo)",
"Pronome demonstrativo | Demonstrative pronoun",
"Palavras conectivas | Connective words",
"Fórmulas Balanceamento e Leis ponderais das reações químicas",
"Redação - Reescritura de texto",
"Regência",
"Verbos | Verbos",
"Modo Verbal (Indicativo Subjuntivo e Imperativo) | Modo Verbal (Indicativo Subjuntivo e Imperativo)",
"Pronomes | Pronombres",
"Formas Átonas | Adjetivos Posesivos",
"Demonstrativos | Pronombres Demonstrativos",
"Geografia Física",
"Relevo",
"Simbolismo",
"Realismo",
"Tradução | Translation",
"Verbos | Verbs",
"Desenvolvimento",
"República Oligárquica - 1889 a 1930",
"Estatística",
"Ecologia e ciências ambientais",
"Cadeias e teias alimentares",
"Qualidade de vida das populações humanas",
"Hábitos alimentares e exercícios físicos",
"Hidrostática",
"Estática e Hidrostática",
"Locução Verbal",
"Aspectos linguísticos | Linguistic aspects",
"Análise sintática | Syntax Parsing",
"Grandezas e Unidades",
"Conteúdos Básicos",
"Problemas ambientais e medidas de conservação",
"Análise de Tabelas e Gráficos",
"Aritmética e Problemas",
"Porcentagem",
"Orações subordinadas reduzidas",
"História Geral",
"Renascimento Científico Artístico e Cultural",
"Antiguidade Ocidental (Gregos Romanos e Macedônios)",
"Transformações Químicas e Energia",
"Eletroquímica: Oxirredução Potenciais Padrão de Redução Pilha Eletrólise e Leis de Faraday.",
"Transformações Químicas",
"Substâncias e suas propriedades",
"Transformações: Estados Físicos e Fenômenos",
"Transformações Químicas: elementos químicos tabela periódica e reações químicas",
"Interações Atômicas: Geometria Molecular Polaridade da ligação e da Molécula Forças Intermoleculares e Número de Oxidação.",
"Orações subordinadas substantivas: Subjetivas Objetivas diretas Objetivas indiretas...",
"Interações Atômicas: Ligações Iônicas Ligações Covalentes e Ligações Metálicas. Ligas Metálicas.",
"Química Orgânica",
"Principais Funções Orgânicas: Funções Oxigenadas: Cetona Aldeído Éter Éster Ácido Carboxílico Anidrido Orgânico e Cloreto de Ácido.",
"Tipos de Reações Orgânicas: Substituição Adição e Eliminação.",
"Magnetismo",
"Magnetismo Elementar",
"Geometria Espacial",
"Prismas",
"Substâncias Inorgânicas e suas características: Ácidos Bases Sais e Óxidos. Reações de Neutralização.",
"Propriedades Físicas dos Compostos Orgânicos: Polaridade das Ligações e Moléculas Forças Intermoleculares Ponto de Fusão e Ponto de Ebulição Solubilização das Substâncias Orgânicas.",
"Pronome relativo | Relative clauses",
"Vegetação",
"Estudo da matéria: substâncias misturas processos de separação.",
"Geometria Plana",
"Áreas e Perímetros",
"Razão e Proporção; e Números Proporcionais",
"Guerra Fria e seus desdobramentos",
"Raciocínio Analítico",
"Cartografia",
"Coordenadas Geográficas",
"Flexão de voz (ativa passiva reflexiva)",
"Sistema de Unidade de Medidas",
"Energias Químicas no Cotidiano",
"Principais Funções Orgânicas: Hidrocarbonetos: Alcano Alceno Alcino Alcadieno Ciclos Alcano e Alceno Aromáticos. Haletos.",
"Petróleo Gás Natural e Carvão Madeira Hulha Biomassa Biocombustíveis e Energia Nuclear",
"Principais Funções Orgânicas: Funções Oxigenadas: Álcool Fenol e Enol.",
"Leis de Newton",
"Força Gravitacional e Satélites",
"Dinâmica",
"Gravitação Universal",
"Radioatividade: Reações de Fissão e Fusão Nuclear Desintegração Radioativa e Radioisótopos.",
"Vírus e bactérias",
"História e Geografia do Estado da Bahia",
"Cinética Química",
"Velocidade de Reação Energia de Ativação Concentração Pressão Temperatura e Catalisador",
"Agricultura brasileira",
"Hidrografia",
"Relações Métricas no Triângulo Retângulo",
"Diagramas de Venn (Conjuntos)",
"Ciências",
"Propriedades da Água",
"Pronomes pessoais oblíquos",
"Colocação Pronominal",
"Geografia Econômica",
"Cadeias Carbônicas: Características e Classificações do Átomo do Carbono Tipos de Ligação e Hibridação. Tipos de Cadeias Carbônicas e Fórmulas. Séries: Homóloga Isóloga e Heteróloga.",
"Protistas e algas",
"Barroco",
"Romantismo",
"Naturalismo",
"Período Entre-Guerras: Totalitarismos",
"Relações da Química com as Tecnologias a Sociedade e o Meio Ambiente",
"Voz Ativa e Passiva | Passive and Active Voice",
"Ângulos - Lei Angular de Thales",
"Período Colonial: produção de riqueza e escravismo",
"Classificação biológica",
"Introdução aos estudos das Plantas",
"Trigonometria",
"Funções Trigonométricas e Funções Trigonométricas Inversas",
"Teoria Atômica: átomos e sua estrutura - número atômico número de massa isótopos massa atômica",
"Funções morfossintáticas da palavra QUE",
"Ondas e Propriedades Ondulatórias",
"Oscilação e Ondas",
"Impulso e Quantidade de Movimento",
"Cinemática",
"Fundamentos da Cinemática",
"Transcrição e Tradução",
"Biomas brasileiros",
"Progressões",
"Progressão Aritmética - PA",
"Matrizes",
"Álgebra Linear",
"Movimento Retilíneo Uniforme",
"Termoquímica: Energia Calorífica Calor de reação Entalpia Equações e Lei de Hess.",
"Médias",
"Uso das aspas",
"Verbos modais | Modal verbs",
"Chuva Ácida e Efeito Estufa",
"República Autoritária : 1964- 1984",
"Substantivos",
"Progressão Geométrica - PG",
"Álgebra",
"Problemas",
"Raciocínio Matemático",
"Probabilidade",
"Funções",
"Função de 1º Grau ou Função Afim Problemas com Equação e Inequações",
"Discurso direto e indireto | Reported speech",
"Presente perfeito | Present perfect",
"Equilíbrio Químico",
"Sistemas Homogêneos: Constantes: Kc e Kp. Deslocamento do Equilíbrio: Fatores.",
"Teoria Quântica",
"Câncer",
"Trabalho e Energia",
"Colisão",
"Temperatura e Escalas Termométricas",
"Sistemas Homogêneos: Equilíbrio Químico na Água: pH e pOH Indicadores Ácido-Base  Solução Tampão.",
"Regra de Três",
"Isomeria: Isomeria Plana: Cadeia Posição Compensação Função e Tautomeria.",
"Isomeria: Isomeria Espacial: Isomeria Geométrica (cis-trans) e Isomeria Óptica.",
"Ortografia",
"Acentuação Gráfica: acento diferencial",
"Os advérbios (grau a finalidade contraste ...) | Adverbs (degree purpose contrast...)",
"Fotossíntese",
"Circunferências e Círculos",
"Propriedades Coligativas: Tonoscopia Ebulioscopia Crioscopia e Pressão Osmótica.",
"Sequências Lógicas de Números Letras Palavras e Figuras",
"Função Exponencial",
"Funções morfossintáticas da palavra ATÉ",
"Estática - Momento da Força/Equilíbrio e Alavancas",
"Logaritmos",
"Quadriláteros",
"Geometria Básica",
"Principais doenças endêmicas no Brasil",
"Gráficos do MRU e MRUV",
"Funções morfossintáticas da palavra SE",
"Potência",
"Órgãos dos sentidos",
"Introdução ao Estudo dos Animais",
"Lentes",
"Formação de palavras (prefixos e sufixos) | Word formation (prefix and suffix)",
"Política Nacional",
"Política",
"Sistemas Heterogêneos: Produto de Solubilidade (Kps).",
"Pronome objetivo | Objective pronoun",
"Polígonos",
"Parênteses",
"Atualidades do ano de 2016",
"Industrialização",
"Indústria mundial contemporânea",
"Intertextualidade",
"Formas nominais do verbo (particípio gerúndio infinitivo)",
"Mal-Mau",
"Estrutura das Palavras: Radical Desinência Prefixo e Sufixo",
"Atualidades do ano de 2015",
"Demandas políticas e sociais no mundo atual",
"Tag questions",
"Amazônia",
"Pronomes relativos",
"Pronomes Indefinidos",
"Sílaba: Monossílabos Dissílabos Trissílabos Polissílabos",
"Acentuação Gráfica: Proparoxítonas Paroxítonas Oxítonas e Hiatos",
"Arcadismo",
"Introdução à genética: 1ª e 2ª leis de Mendel",
"Refração",
"Advérbios",
"Substâncias Inorgânicas: dissociação iônica e ionização conceitos de ácido-base.",
"Sistema Imunológico",
"Vertebrados",
"Uma visão geral da célula",
"Cargas Elétricas e Eletrização",
"Triângulos",
"Formas Tônicas | Posesivos",
"Conjunções | Conjunciones",
"Sinônimos | Sinónimo",
"Seno Cosseno e Tangente",
"Tradução",
"Acentuação | El Acento y la Tilde",
"Gramática",
"Origem e evolução da vida",
"Evolução biológica",
"Fungos",
"Meio Ambiente Sustentabilidade e Aquecimento Global na Atualidade",
"Física Moderna",
"Lançamento Horizontal",
"Teoria Literária",
"Uso das reticências",
"Astronomia Observacional",
"História da Arte e Educação",
"Relação entre Indivíduo e Sociedade",
"Caso genitivo | Genitive case",
"Pressão",
"Infinitivo e gerúndio | Infinitive and gerund",
"Termos integrantes da oração: predicativo do sujeito e predicativo do objeto",
"Indústria brasileira",
"Sistemas Gasosos - Lei Teoria Cinética Equação e Mistura dos Gases. Princípio de Avogadro.",
"Determinantes e quantificadores | Determiners and quantifiers",
"Pronome possessivo adjetivo | Possessive adjective",
"Movimento Retilíneo Uniformemente Variado",
"Classificação dos verbos (Regulares Irregulares Defectivos Abundantes Unipessoais Pronominais)",
"Estrutura do verbo (radical vogal temática desinências)",
"Parônimos e Homônimos",
"Substantivos e compostos | Nouns and compounds",
"Plural dos substantivos | Plural of nouns",
"Energia Mecânica e sua Conservação",
"Vanguardas Europeias",
"A química da vida",
"Clima",
"Revolução Intelectual do século XVIII: Iluminismo",
"Emprego do hífen",
"Poliedros",
"Desastres Naturais e Humanos na Atualidade",
"Análise Combinatória em Matemática",
"Segunda Grande Guerra – 1939-1945",
"Uso do ponto do ponto de exclamação e do ponto de interrogação",
"Pronomes pessoais retos",
"Número | Número",
"Flexão do Nome | Flexíon de las Palabras",
"Passado simples | Simple past",
"Glicídios Lipídios Aminoácidos e Proteínas.",
"O Meio Ambiente",
"Fundamentos",
"Poluição e Crise Ambiental",
"Saneamento Geral",
"Resíduos Sólidos",
"Meio Terrestre",
"Artigos | Artículos",
"Definidos | Artículo Determinado",
"Indefinidos | Artículo Indeterminado",
"Neutro | Artículo Neutro",
"Contrações | Contraccíon o Formas Contraídas",
"Geometria Analítica",
"Pontos e Retas",
"Preposições | Prepositions",
"Modernismo: Tendências contemporâneas",
"Artigos | Articles",
"IST´s e Métodos contraceptivos",
"Propriedades Químicas dos Compostos Orgânicos: Número de Oxidação do Carbono. Efeitos Eletrônicos. Caráter Ácido-Base.",
"Parábola",
"Função de 2º Grau ou Função Quadrática e Inequações",
"Raciocínio Lógico",
"Mutações e alterações cromossômicas",
"Sistema Respiratório Humano",
"Equações Polinomiais",
"Física Matemática",
"Fundamentos de Lógica",
"Proposições Simples e Compostas e Operadores Lógicos",
"Polímeros - Plásticos e Borrachas",
"Produtos Notáveis e Fatoração",
"Cilindro",
"Eletrodinâmica - Corrente Elétrica",
"Frações e Números Decimais",
"Função Logarítmica",
"Pirâmides",
"Interjeições",
"Orações subordinadas adjetivas: Restritivas Explicativas",
"Relações ecológicas",
"Plano Inclinado e Atrito",
"Sistemas de Numeração  e  Operações Fundamentais",
"Termologia/Termometria",
"Química",
"Esfera",
"Primeira Guerra Mundial",
"Gimnospermas e Angiospermas",
"A Política",
"Maquiavel e O Príncipe",
"Construção do Estado Liberal: Revolução Francesa",
"Migração Refugiados e Demografia na Atualidade",
"Brasil Monárquico – Primeiro Reinado 1822- 1831",
"Construção do Estado Liberal: Independência das colônias latino-americanas",
"Calor Latente",
"Travessão",
"História da América Latina",
"Independências das regiões hispano-americanas: México América Central e América do Sul",
"Medievalidade Europeia",
"Movimentos de Reforma Religiosa: protestantes e católicos",
"Modernismo: Concretismo",
"Revolução Industrial",
"Processo de Independência: dos movimentos nativistas à libertação de Portugal",
"Expansão Comercial a Marítima: a busca de novos mundos",
"Quinhentismo",
"Espelhos Esféricos",
"Circuitos Elétricos e Leis de Kirchhoff",
"2ª Lei da Termodinâmica - Ciclo de Carnot e Máquinas Térmicas",
"A origem da vida na Terra",
"América Latina na segunda metade do século XX: revoluções transformações e permanências",
"A estruturação do Estado norte-americano : território cidadania e política",
"Guerra Fria e as ditaduras militares",
"Conhecimentos Gerais Sobre Arte e Cultura",
"Revoluções Liberais na Europa : Ondas de 1820 1830 e 1848",
"Questões Sociais",
"Movimentos Sociais Discriminação e Desigualdade: Raça classe e gênero",
"Meio Ambiente Sustentabilidade e Aquecimento Global",
"Política Internacional",
"Economia",
"Economia Internacional",
"Economia Nacional",
"Trabalho Transporte Previdência e outras Questões Sociais",
"Mercantilismo e a economia de Estados",
"Arcos",
"Presente perfeito progressivo | Present perfect continuous",
"Guerras Conflitos e Terrorismo",
"Conhecimentos Gerais Sobre a América Latina",
"Sistemas Homogêneos: Equilíbrio Iônico: Conceitos Diluição de Ostwald Efeito do Íon Comum.",
"Sistema Reprodutor Humano",
"Uso de tú-usted | El Uso del “Tú” y el “Usted”",
"Escalas",
"Núcleo interfásico e código genético",
"Crescimento",
"Transformações Gasosas",
"Grafia e Emprego de Iniciais Maiúsculas",
"Projeções e Representações",
"Análise Sintática | Análisis Sintáctico",
"Preposições | Prepocisiones",
"Fuso Horário",
"Descolonização Afro-asiática : novos Estados nova arena internacional",
"Geografia Cultural",
"Verbos frasais | Phrasal verbs",
"Reflexão",
"Movimento Harmônico",
"Verbos auxiliares",
"Filosofia e a Grécia Antiga",
"Sócrates e a Maiêutica",
"Os Contratualistas (Hobbes Locke e Rousseau)",
"Principais Funções Orgânicas: Funções Nitrogenadas: Amina Amida Nitrila Isonitrila e Nitro Composto.",
"Artrópodes",
"Números | Numbers",
"Advérbios de: lugar modo tempo e freqüência | Adverbs of: place manner time and frequency",
"Primeira metade do século XX: Revolução Mexicana peronismo e cardenismo",
"Acústica",
"Fonologia",
"Noções de Fonética",
"Fonemas e Letras",
"Encontros vocálicos: Ditongo Tritongo Hiato",
"Estudo dos tecidos",
"Pronome Relativo | Pronombres Relativos Exclamativos e Interrogativos",
"Gêneros Literários",
"Gênero Épico ou Narrativo",
"Classificação dos Gêneros Literários",
"Gênero Lírico",
"Estilística",
"Versificação - Poemas de Forma Fixa",
"Física Atômica e Nuclear",
"Reconstrução Democrática : Governo Sarney",
"Reconstrução Democrática: Governo Collor e o Impeachment",
"Reconstrução Democrática: Governos FHC",
"Reconstrução Democrática: Governos Lula e Dilma – (PT no poder)",
"Níveis de organização da vida",
"Versificação - Ritmo e Estrofes",
"Uso dos adjetivos | Use of adjectives",
"Espelhos Planos",
"Sistema Esquelético e Muscular Humano",
"Presente simples | Simple present",
"Ocupação de novos territórios: Colonialismo",
"Sistemas Lineares",
"Numerais | Numerales",
"1ª Lei da Termodinâmica",
"Oposto | Opposite",
"Substantivos contáveis e incontáveis | Countable and uncountable",
"Versificação - Metrificação",
"Futuro simples | Simple future",
"Orações condicionais | Conditional Clauses",
"Pronome possessivo substantivo | Possessive pronoun",
"Campo e Força Magnética",
"Pronomes interrogativos | Question words",
"Produção e consumo mundial",
"Solo",
"Questões Internacionais: história do tempo presente",
"Sistema Excretor Humano",
"Gás Ideal",
"Orações Intercaladas",
"Indução e Transformadores Elétricos",
"Tipos de Reações Orgânicas: Oxidação Redução e Polimerização.",
"Estudo da Reta",
"Mercantilismo Colonialismo e a ocupação portuguesa no Brasil",
"Conceitos Demográficos",
"Meiose",
"Eletrostática e Lei de Coulomb. Força Elétrica.",
"Vetores",
"Sistemas Homogêneos: Solubilidade dos Sais Hidrólise dos Sais e Curvas de Titulação.",
"História e Geografia do Estado de Goiás",
"Conceitos Filosóficos",
"Sistema Nervoso Humano",
"Ciclos biogeoquímicos",
"Mudanças climáticas",
"Análise Combinatória em Raciocínio Lógico",
"Semelhança de Triângulo",
"Pré-História Brasileira: Legado de povos nativos",
"Civilizações Pré-Colombianas: Maias Aztecas e Incas",
"Cone",
"Platão e o Mundo das Ideias",
"Sistema Digestório Humano",
"Teoria Atômica: Modelo atômico de Dalton Thomson Rutherford Rutherford-Bohr",
"O Sujeito Moderno",
"Surfactantes",
"Produção e consumo no Brasil",
"Conhecimentos Gerais Sobre Ciência Tecnologia e Inovação",
"Pronome Reflexivo | Pronombre Reflexivo Complemento Directo e Indirecto",
"Imperativo | Imperative",
"Pronome reflexivo | Reflexive Pronoun",
"Termodinâmica: Energia Interna Trabalho Energia Livre de Gibbs e Entropia",
"Queda Livre",
"Pessoais | Pronombres Personales",
"Herança ligada ao sexo",
"Numerais",
"Encontros consonantais: Dígrafos",
"Presente progressivo | Present continuous",
"Imperativo | Imperativo Afirmativo y Negativo",
"Gênero | Género",
"Antônimos | Antónimo",
"Formas Nominais | Formas no Personales (Infinitivo Gerúndio y Particípio)",
"Passado progressivo | Past continuous",
"Passado perfeito | Past perfect",
"Tipos de Reagentes e Mecanismos das Reações Orgânicas.",
"Adjetivos em Espanhol | Adjetivos",
"Características dos seres vivos",
"Briófitas e Pteridófitas",
"Gametogênese",
"Falsos Cognatos | Falsos Amigos",
"Heterogenéricos | Heterogenéricos",
"Futuro perfeito | Future perfect",
"Tempos Verbais do Subjuntivo | Tiempos Verbales en Subjuntivo (Presente Pretérito Imperfecto o Perfecto Pretérito Pluscuamperfecto)",
"Tempos Verbais do Indicativo | Tiempos Verbales del Indicativo (Presente Pretérito Perfecto Simple o Compuesto Pretérito Imperfecto Pretérito Pluscuamperfecto Futuro Simple o Compuesto)",
"Futuro perfeito progressivo | Future perfect continuous",
"Futuro progressivo | Future continuous",
"Fonética | Fonética",
"Passado perfeito progressivo | Past perfect continuous",
"Técnicas de Laboratório",
"Vidrarias",
"Termodinâmica e Equilíbrio de Fases",
"Equipamentos e Técnicas Básicas",
"Tradução | Traducción",
"Mundo do trabalho",
"Globalização reestruturação produtiva e mudanças recentes do trabalho",
"Leis de Kepler",
"Instrumentos Ópticos",
"Falso Cognatos | False Cognates",
"Construção de Estados e o Absolutismo",
"Período Entre-Guerras; Crise de 1929 e seus desdobramentos",
"Cultura e sociedade",
"Cultura de massa e indústria cultural",
"Cultura e identidade nacional",
"Estrutura e fisiologia da Membrana Plasmática",
"Fundamentos da História : Tempo Memória e Cultura",
"Linguagem e Pensamento",
"Embriologia humana",
"História da Geografia",
"Categorias de Análise da Geografia",
"Política poder e Estado",
"Introdução ao metabolismo energético",
"História e Geografia do Estado do Paraná",
"Equação Logarítmica",
"Pré-História ou História Não-Escrita",
"MCU - Movimento Circular Uniforme",
"Estratificação e desigualdade social",
"Desigualdades de raça classe e gênero",
"Composição nas Artes Visuais",
"Linguagens Artísticas",
"Orações coordenadas assindéticas",
"Estado de Bem-Estar Social. Políticas Públicas e Políticas Sociais no Brasil e no Mundo",
"Linguagens em Artes Visuais",
"Artes Gráficas",
"Mídias e Artes Visuais",
"Relatividade",
"Flexão de Voz (Voz Ativa e Passiva) | Las Voces del Verbo (Activa y Pasiva)",
"Aquecimento global",
"Atualidades do ano de 2020",
"América Latina na Atualidade",
"Urbanização em países subdesenvolvidos",
"Associação de Resistores",
"Biologia como ciência",
"Sistemas agrícolas",
"Agricultura tradicional",
"Pirâmide etária",
"Lançamento Oblíquo",
"Relações Trigonométricas no Triângulo Retângulo Leis dos Senos e Cossenos.",
"Linguagem Visual",
"Integração Regional",
"Regionalização Brasileira",
"Lançamento Vertical",
"Mitose",
"Vícios da linguagem",
"Bioquímica em Engenharia Química e Química Industrial",
"Impactos e soluções no meio urbano",
"Urbanização em países desenvolvidos",
"Análises Clínicas",
"Bioquímica Básica e Clínica",
"Dinâmica de populações",
"Platelmintos e Nematódeos",
"Fórmula de Compasso",
"Clave",
"Intervalos",
"Imperialismo e Colonialismo do século XIX",
"Trinta anos de ouro: mundo capitalista (1945- 1975)",
"Graus da escala",
"Tonalidade",
"Acorde",
"Instrumentos Musicais",
"Estatística descritiva (análise exploratória de dados)",
"Medidas de Dispersão (Amplitude Desvio Médio Variância Desvio Padrão e Coeficiente de Variação)",
"Geologia Geral",
"Geologia Ambiental",
"Geologia Histórica",
"Conceitos Fundamentais",
"Teoria e Abordagem Arquivística",
"História da Arquivologia",
"Princípio de administração",
"Ciclo Celular",
"Cifras",
"Sinalização de trânsito",
"Segurança dos Veículos: Requisitos Condições e Equipamentos Obrigatórios",
"Normas gerais de circulação e conduta",
"Educação para o trânsito",
"Pedestres e condutores de veículos não motorizados",
"Política Nacional de Mobilidade Urbana - Lei 12.587/2012",
"Definições. Dos princípios diretrizes e objetivos da política nacional de mobilidade urbana",
"Das diretrizes para o planejamento e gestão dos sistemas de mobilidade urbana",
"Estatuto da Cidade - Lei 10.257/2001",
"Diretrizes Gerais",
"Dos instrumentos de apoio à mobilidade urbana",
"Linguagens de programação",
"Pascal",
"História e Atualidades das Artes Cênicas",
"Linguagens e Suporte em Artes Plásticas",
"Musicalidade e Corporeidade",
"Linguagem e Encenação Teatral",
"Evolução humana",
"A Razão e seus Sentidos",
"São Tomás e a Filosofia Medieval",
"Administração de Serviços de Alimentação",
"Rotulagem de Alimentos",
"Conhecimentos de estatística",
"Medidas de Posição - Tendência Central (Media Mediana e Moda)",
"Cálculo de Probabilidades",
"Probabilidade condicional Teorema de Bayes e independência",
"Gráficos estatísticos - Barras ou Colunas e Histograma",
"Distribuições de frequência",
"Variável aleatória discreta",
"Dengue",
"Doenças Infecciosas e parasitárias",
"Microeconomia",
"Teoria da produção",
"Administração de Arquivos",
"Competências dos Profissionais de Arquivo",
"Gestão de Bibliotecas e Unidades de Informação",
"Termos especiais",
"Equação de 2º Grau e Problemas de 2º Grau",
"Lei nº 9.503 de 1997 - Código de Trânsito Brasileiro - CTB: visão geral e como estudar. Disposições Preliminares Conceitos e Definições.",
"Das diretrizes para a regulação dos serviços de transporte público coletivo",
"Artes Cênicas e Educação",
"Formação do Espectador",
"Geologia Estrutural",
"Petrologia",
"Estratigrafia",
"Teorias do Sujeito na Filosofia Moderna",
"A Experiência do Sagrado",
"O Conhecimento Humano e o Conhecimento Divino",
"Epidemiologia e Saúde Coletiva",
"Geologia",
"Do plano diretor",
"Astronomia",
"Lei dos Senos",
"Materiais de Construção Mecânica",
"Propriedades e Comportamentos dos Materiais",
"Razão: Inata ou Adquirida?",
"Interpretação de Texto | Interpretazione di Testo",
"Interpretação de Textos | Textinterpretation",
"Polígonos Regulares",
"Emprego do infinitivo (Infinitivo impessoal Infinitivo  pessoal)",
"Polinômios",
"Equações Biquadradas e Equações Irracionais",
"Divisão Silábica",
"Problemas Lógicos",
"Circunferências",
"Radical",
"Números Complexos",
"Equivalência Lógica e Negação de Proposições",
"Equivalências - Proposições Logicamente Equivalentes",
"Atenção Básica e Saúde da Família",
"Doenças Infecto-Parasitárias",
"Neurologia",
"Endocrinologia",
"Imunização",
"Cardiologia e Alterações Vasculares",
"Máquina de Atwood  e Associação de Blocos",
"Saúde",
"Aspectos da Filosofia Contemporânea",
"Cultura e Patrimônio",
"Conceito e objeto da antropologia. Cultura. Relativismo cultural versus etnocentrismo. Observação participante e técnica",
"Formas de estratificação social",
"Vontade Divina e Liberdade Humana",
"Provas da Existência Divina: Ontologia Cosmologia e Teleologia",
"História e Atualidades das Artes Plásticas",
"Teorias clássicas do Estado",
"Conceito de poder",
"Democracia e representação política",
"Desigualdade social no Brasil",
"O nascimento da sociologia",
"Revolução e transformação social",
"História e Geografia do Estado de Santa Catarina",
"Versificação - Rimas",
"Drogas",
"Revolução Chinesa",
"Artes Corporais",
"História Estética e Crítica das Artes Visuais",
"Sucessão ecológica",
"Mito e Filosofia",
"As cidades e os espaços urbanos",
"Segregação socioespacial e políticas públicas urbanas",
"Período Entre-Guerras: Revolução Russa de 1917",
"Ética e Liberdade",
"Sistemas Religiosos - Magia Ciência Religião Ritos e Cerimônias. Antropologia Econômica. Divisão do trabalho comércio e consumo",
"A divisão social do trabalho",
"Conhecimentos Gerais sobre Energia",
"Esponjas e Cnidários",
"Números Primos e Divisibilidade",
"Cinemática Vetorial",
"A herança dos grupos sanguíneos",
"MMC e MDC",
"Anelídeos e Moluscos",
"A Metafísica de Aristóteles",
"Colonialismo espanhol: Ocupação e exploração do território americano",
"Projetos e Ações Artístico-culturais",
"Expressão Bidimensional e Tridimensional",
"Elipse",
"Modernização Agrícola",
"Teorias da Comunicação e Semiótica",
"Hipérbole",
"Desenvolvimento Sustentável",
"Avaliação de Impactos Ambientais",
"MCUV - Movimento Circular Uniformemente Variado",
"Em Busca da Verdade",
"Ensino da Arte",
"Linguagem Corporal",
"Tons Vizinhos",
"Pensamentos Políticos - Karl Marx",
"Filosofia da Cultura",
"Noção antropológica de cultura",
"Etnocentrismo e diversidade cultural",
"Técnicas e Materiais Pictóricos e Escultóricos",
"Trinta anos de ouro: expansão do socialismo ( 1945- 1975)",
"Determinantes",
"Educação Teatral",
"O Método dos Modernos: Causalidade Indução Evidências",
"Karl Marx e as Classes Sociais",
"Conhecimentos Gerais sobre Desastres Naturais e Humanos",
"Tautologia Contradição e Contingência",
"Física",
"Movimentos Ondulatórios e Fenômenos Luminosos",
"Noções Gerais de Urbanização",
"Pecuária",
"História e Geografia do Estado do Mato Grosso",
"Sociologia como ciência",
"Auguste Comte e o positivismo",
"Economia na Atualidade",
"Economia Internacional na Atualidade",
"Atualidades do ano de 1994 ao ano de 2013",
"Filosofia Política e o Pensamento de Walter Benjamin",
"Atualidades do ano de 2017",
"Energia na Atualidade",
"Há-a",
"Conflitos na antiga Europa do Leste",
"Lógica de Argumentação - Diagramas e Operadores Lógicos",
"Pronome indefinido | Indefinite Pronouns",
"Émile Durkheim e os Fatos Sociais",
"Consciência Corporal Saúde e Qualidade de Vida",
"Queda do Socialismo Real",
"Construção do Estado Liberal: Independência das Treze Colônias (EUA)",
"Álgebra Linear - Equações Lineares Espaço Vetorial e Transformações Lineares e Matrizes",
"Acerca de- Há cerca de",
"Paralelismo sintático e semântico",
"Funções morfossintáticas da palavra MESMO",
"Metrologia",
"Mecânica dos Sólidos",
"Modelagem Estática e Dinâmica",
"Sistemas Mecânicos",
"Metrologia (Princípios da Cotagem Tolerâncias Folgas e Ajustes)",
"Java",
"Netbeans",
"IDE (Integrated Development Environment)",
"Resistência dos Materiais",
"SQL",
"Hidráulica",
"Elasticidade Linear Plasticidade Fratura Fadiga e Flambagem",
"Elementos de Máquinas",
"Arquitetura TCP/IP",
"Eletrônica Analógica",
"Transistores",
"Sistemas e Processos Construtivos",
"Materiais de construção em Edificações",
"Meteorologia",
"Topografia",
"Processos de Fabricação Mecânica",
"Desenho Técnico Mecânico",
"Gráficos estatísticos - Polígono de Frequências e Ogiva",
"Amplificadores em Eletrônica",
"Migração Refugiados e Demografia",
"Política no Brasil",
"Arte e Cultura na Atualidade",
"Ciência Tecnologia e Inovação na Atualidade",
"Habeas Corpus no Processo Penal Militar",
"Direitos Políticos",
"Poderes da Administração",
"Abuso de Poder",
"Responsabilidade civil do estado",
"Excludentes e atenuantes da responsabilidade civil objetiva e teoria do risco integral",
"Guerras Conflitos e Terrorismo na Atualidade",
"Direitos Políticos no Direito Eleitoral",
"Direitos Políticos Passivos - Capacidade Eleitoral Passiva: Condições de Elegibilidade",
"Evolução da responsabilidade civil estatal - Teoria da irresponsabilidade teorias civilistas e teorias publicistas",
"Responsabilidade do Estado por atos omissivos",
"Previsão constitucional e elementos da responsabilidade civil objetiva do Estado",
"Prisão",
"Responsabilidade do Estado por obras públicas atos legislativos e atos judiciais",
"Atualidades do ano de 2021",
"Pronomes Interrogativos",
"Lei nº 13.765 de 2006 -Estatuto dos Militares Estaduais do Ceará",
"Legislação do Estado do Ceará",
"Lei nº 13.729 de 2006 - Estatuto dos Militares Estaduais do Ceará",
"Lei nº 13.407 de 2003 - Código Disciplinar da Polícia Militar e do Corpo de Bombeiros",
"Segurança da Informação",
"Ferramentas de Segurança (antivírus firewall e outros)",
"Redes de Computadores",
"Navegadores (Browser)",
"Google Chrome",
"Sistema Operacional",
"Windows 8",
"O Padrão Ofício",
"Partes do documento no Padrão Ofício",
"Educação nas Questões Sociais",
"Atualidades do ano de 2014",
"Editor de Textos - Microsoft Word e BrOffice.org Writer",
"Microsoft Word 2013",
"Formatação e Apresentação",
"Backup em Segurança da Informação",
"Planilhas Eletrônicas - Microsoft Excel e BrOffice.org Calc",
"Microsoft Excel 2007",
"Planilha Eletrônica",
"Lei nº 12.086 de 6 de Novembro de 2009 (Polícia Militar e Corpo de Bombeiros Militar do Distrito Federal)",
"Legislação do Distrito Federal - Normas Distritais e Federais",
"Fisiologia",
"Enfermagem em Centro Cirúrgico",
"Saúde da Criança e do Adolescente",
"Diabetes Mellitus",
"Doenças Crônicas Não Transmissíveis",
"Enfermagem em Oncologia",
"Saúde da Mulher",
"Assistência de Enfermagem ao Pré-Natal",
"História da Enfermagem",
"Pesquisa em Enfermagem",
"Estrutura Organizacional dos Serviços de Enfermagem e Gerenciamento do Trabalho",
"Administração em Enfermagem",
"Gerenciamento de Resíduos dos Serviços de Saúde",
"Urgência e emergência",
"Anatomia na Enfermagem",
"APH-Atendimento Pré-Hospitalar",
"Enfermagem e Saúde do Trabalhador",
"Doenças Ocupacionais",
"Epidemiologia e Vigilância Epidemiológica",
"Infecção Hospitalar",
"Bioética",
"Medicina Intensiva",
"Choque e Parada Cardiorrespiratória",
"Infrações",
"Infrações Leves",
"Infrações Médias",
"Infrações Graves",
"Processo administrativo",
"Direção Defensiva",
"Sistema de Injeção",
"Roda e pneu",
"Sistema Eletrico",
"Manutenção - tipos",
"Legislação Trabalhista Brasileira e Internacional",
"Legislação Trabalhista extraordinária (Segurança Corporativa; Segurança do Trabalho pela CRFB; Especialização de Engenheiros; Arquitetura em Engenharia de Segurança do Trabalho - Lei 7.410/85)",
"Malware (vírus worms e pragas virtuais)",
"Mozilla Firefox",
"Internet Explorer",
"Internet",
"Protocolo e Serviço",
"LibreOffice e BrOffice Writer",
"Microsoft Word 2010",
"Lei no 7.479 de 2 de Junho de 1986 (Estatuto do Corpo de Bombeiros Militar do Distrito Federal)",
"Envenenamentos e Acidentes",
"Traumas",
"Queimaduras",
"Anatomia e Fisiologia Humana em Medicina",
"Pacote de Aplicativos: Microsoft Office BrOffice OpenOffice e LibreOffice",
"Correio Eletrônico (cliente de e-mail e webmail)",
"Mozilla Thunderbird",
"Microsoft Outlook",
"Windows XP",
"Portarias do Ministério da Saúde",
"As Comunicações Oficiais",
"Elementos de Gramática - Sintaxe",
"Sintaxe - Aspectos Gerais",
"Aspectos gerais da Comunicação Oficial",
"Análise de Vulnerabilidade e Gestão de Riscos",
"Plano de Continuidade de Negócios",
"Linux",
"Comandos",
"Windows",
"Windows 7",
"COBIT",
"ITIL",
"ITIL v. 3",
"Conceitos básicos no Gerenciamento de Projetos",
"Conceitos Básicos em Governança de TI",
"Gestão Estratégica",
"Competência da Justiça Militar",
"Princípios fundamentais do direito processual penal",
"Juiz natural",
"Recursos Criminais",
"Recurso em sentido estrito",
"Crime Militar",
"Tipicidade",
"Noções Fundamentais",
"Conceitos e caracteres",
"Desistência voluntária e arrependimento eficaz",
"Planos de Benefício da Previdência Social - Lei nº 8.213 de 24 de Julho de 1991",
"Benefícios em Espécie",
"Aposentadoria por Idade",
"Regime Geral de Previdência Social - RGPS",
"Beneficiários do RGPS",
"Auxílio-Doença",
"Direitos Individuais",
"Direitos Sociais",
"Direitos Individuais - Remédios Constitucionais e Garantias Processuais",
"Habeas Corpus",
"Serviços Públicos",
"Delegação dos Serviços Públicos - Concessão e Permissão",
"Licitações e Lei 8.666 de 1993.",
"Princípios das Licitações",
"Crimes contra a administração pública",
"Desobediência",
"Desacato",
"Legislação Penal Especial",
"Lei do Abuso de Autoridade – Lei nº 4.898 de 1965 e Lei n° 13.869 de 2019",
"Crimes contra o patrimônio",
"Furto",
"Antijuridicidade",
"Lei de Crimes Hediondos – Lei nº 8.072 de 1990",
"Habeas Corpus no Processo Penal",
"Meios Autônomos de Impugnação",
"Das Provas",
"Sistemas de apreciação e valoração",
"Inquérito Policial Militar - IPM",
"Beneficiários do Regime Geral de Previdência Social - RGPS",
"Segurados Obrigatórios - Empregado",
"Seguridade Social",
"Origem e evolução",
"Prescrição e Decadência",
"Parte Geral",
"Dos Atos Processuais",
"Prazos",
"Da Formação Suspensão e Extinção do Processo",
"Da formação do processo",
"Segurança Pública",
"Regime jurídico administrativo",
"Princípios - Legalidade Impessoalidade Moralidade Publicidade e Eficiência",
"Organização do Estado – Estados",
"Organização Político-Administrativa do Estado",
"Direitos Constitucionais-Penais e Garantias Constitucionais do Processo",
"Direito à Vida",
"Organização do Estado – Municípios",
"Improbidade administrativa - Lei nº 8.429 de 1992 e Lei nº 14.230 de 2021",
"Demais disposições da Lei 8.429/92",
"Concurso de Pessoas",
"Autoria e coautoria",
"Participação",
"Defesa do Estado e das Instituições Democráticas",
"Forças Armadas e Segurança Pública",
"Intervenção Psicossocial",
"Psicologia Social",
"Conceito Histórico Atuação e Objetos de Estudo da Psicologia Social",
"Representações Sociais Atitudes Comportamento Estereótipos e Preconceitos",
"Metodologia de Pesquisa e Estatística Aplicada à Psicologia",
"Politicas Públicas e Aspectos Legais",
"Meio Atmosfétrico",
"Tessitura",
"Administração de medicamentos",
"Cálculo de Solução e Medicamentos",
"Assistência de Enfermagem em Feridas e Curativos",
"Pós-Operatório",
"Fundamentos e Procedimentos de Enfermagem",
"Assistência de Enfermagem ao Recém-Nascido",
"Teorias da Enfermagem",
"Parada Cardiorespiratória (PCR)",
"Legislação de Enfermagem",
"Código de Ética dos Profissionais de Enfermagem",
"Sistema Único de Saúde - SUS",
"Lei Orgânica da Saúde - Lei n° 8.080/1990",
"Aspectos Constitucionais",
"Direito à Saúde no Direito Sanitário",
"Principais distribuições de probabilidade",
"Distribuição Uniforme",
"Covariância Correlação",
"Amostragem",
"Amostragem aleatória simples",
"Distribuição qui-quadrado",
"Distribuição Normal",
"Distribuição  Binomial",
"Variável aleatória contínua",
"Estatística não paramétrica",
"Definição do método",
"Tamanho da amostra",
"Afogamento",
"Doenças Reumatológicas e do Sistema Imunológico",
"Ginecologia e Obstetrícia",
"Geriatria",
"Urologia",
"Clínica Médica Humana",
"Traumatismos Dentários",
"Oclusão",
"Farmacologia na Odontologia",
"Anatomia Aplicada à Odontologia",
"Anatomia Dentária",
"Odontologia Pré-Clínica",
"Dentes Permanentes",
"Cirurgia Maxilofacial. Acidentes e Complicações.",
"Emergências Médicas em Odontologia",
"Equipamentos e Instrumentos Odontológicos",
"Disfunção Temporomandibular (DTM)",
"Odontologia Hospitalar",
"Habilitação na Legislação de Trânsito",
"Infrações Gravíssimas",
"Resoluções do CONTRAN",
"Identificação do veículo",
"Sistema Nacional de Trânsito",
"Disposições Gerais e Composição do SNT",
"Sistema de freios",
"Sistema de arrefecimento",
"Proposições Categóricas",
"Sistema Operacional Linux em Noções de Informática",
"Windows Explorer - conceitos de organização de pastas e arquivos",
"Extensão de Arquivo",
"Signatário",
"Segurança de Redes",
"SOA (Service-oriented architecture)",
"OLAP (On-line Analytical Processing)",
"JSP (JavaServer Pages)",
"Índices",
"Oracle",
"Equipamentos de Redes",
"Switches",
"Protocolo",
"IP (Internet Protocol)",
"Acesso Remoto  - VPN (Virtual Private Network) Software para Acesso Remoto e Team Viwer",
"Conceitos fundamentais de Matemática Financeira",
"Fluxo de Caixa",
"Juros compostos",
"Demonstração do Resultado do Exercício",
"Tributos",
"Balanço Patrimonial",
"Legislação Tributária na Contabilidade Geral",
"Operações envolvendo Tributos",
"Folha de Pagamento",
"Operações com Mercadorias",
"Investimentos",
"Consolidação das Demonstrações Contábeis",
"Demonstrações Contábeis",
"Balanço Orçamentário - BO",
"Balanço Patrimonial - BP",
"Execução de Sentença e dos Incidentes de Execução",
"Processo Penal Militar",
"Da Prisão e da Liberdade Provisória",
"Da Prisão Preventiva",
"Aposentadoria por Tempo de Contribuição",
"Contratos em Espécie",
"Prestação de Serviços e Empreitada",
"Tutela Antecipada",
"Procedimento ordinário",
"Cumprimento de sentença",
"Obrigações de fazer e não fazer/tutela inibitória: astreinte",
"Mandado de Segurança Individual e Coletivo",
"Ações Coletivas no CPC 1973",
"Ação Civil Pública no CPC 1973",
"Organização da Administração Pública",
"Administração Direta",
"Administração Indireta",
"Empresas Públicas e Sociedades de Economia Mista",
"Poder de polícia",
"Poder normativo poder hierárquico e poder disciplinar",
"Repartição de Competências Constitucionais",
"Classificação das Constituições",
"Teoria da Constituição",
"Microsoft Word 2007",
"Mandado de Segurança",
"Mandado de Injunção",
"Controle da administração pública",
"Controle administrativo judicial e legislativo",
"Causas relativamente independentes",
"Peculato",
"Crimes contra o Consumidor a Ordem Econômica e Tributária – Lei nº 8.078 de 1990 e Lei nº 8.137 de 1990",
"Culpabilidade",
"Inimputabilidade pela embriaguez",
"Ação Penal",
"Ação penal de iniciativa privada: definição princípios e espécies",
"Segurados Obrigatórios - Segurado Especial",
"Recursos",
"Efeitos dos recursos",
"Direito das Obrigações",
"Adimplemento Modalidades de Pagamento e Extinção das Obrigações",
"Lei Orgânica do Distrito Federal",
"Lei Complementar 97 de 1999 - Organização preparo e emprego das Forças Armadas",
"Estado de Defesa",
"Estado de Sítio",
"Organização do Estado - União",
"Intervenção Federal e Estadual",
"Princípios Fundamentais da República",
"Forma Sistema e Fundamentos da República",
"Nulidades no Processo Penal",
"Procedimento Penal",
"Espécies de nulidades",
"Procedimento especial dos crimes praticados por servidores públicos contra a administração em geral",
"Inquérito Policial",
"Notícia-crime e instauração",
"Assistência de Enfermagem no Processo Nutricional",
"Integral",
"Trans-Operatório",
"Enfermagem em Central de Material Esterilizado",
"Princípios Objetivos Diretrizes e Atribuições.",
"Análise Multivariada",
"Componentes principais",
"Funções de Probabilidade p(x) e Densidade f(x)",
"Distribuição t de student",
"Teste de Kolmogorov-Smirnov",
"Pneumologia",
"Intoxicações Exógenas",
"Oncologia",
"Anestesiologia em Odontologia",
"Materiais Odontológicos",
"Legislação Específica de Trânsito",
"Sistema de Lubrificação",
"Direção",
"Firewall",
"ISO 27002",
"WebServices",
"Banco de Dados",
"Hibernate",
"Frameworks Java",
"JSF (Java Server Faces)",
"Backup em Banco de Dados",
"Modelo relacional",
"Arquiteturas de Rede",
"Ethernet",
"RAID (Redundant Array of Independent Disks)",
"Armazenamento de Dados em Redes de Computadores",
"Extinção da Punibilidade",
"Potencial conhecimento da ilicitude – Erro de proibição",
"Erro do tipo essencial",
"Pensão por Morte",
"Ato Jurídico Fato Jurídico e Teoria Geral do Negócio Jurídico",
"Defeitos do Negócio Jurídico",
"Domicílio e Bens",
"Responsabilidade civil",
"Órgãos Públicos",
"Métodos de Interpretação Constitucional",
"Roubo",
"Lei de Tóxicos – Lei nº 11.343 de 2006",
"Da Prisão em Flagrante",
"Regimes da Previdência Social",
"Regimes Complementares de Previdência (Privada e Pública)",
"Financiamento do Regime Geral da Previdência Social",
"Organização do Estado - Distrito Federal e Territórios",
"Lei da Interceptação Telefônica - Lei nº 9.296 de 1996",
"Salário de Contribuição",
"Parcelas integrantes e não integrantes",
"Primeiros Socorros",
"Sangramentos",
"Sistema de Amortização Constante (SAC)",
"Cuidados de Enfermagem",
"Farmacologia e Anestesiologia",
"Planos de contingência",
"Políticas de Segurança de Informação",
"Processo Administrativo - Lei nº 9.784 de 1999 e Lei nº 14.210 de 2021",
"Demais aspectos da lei 9.784/99",
"Reparação do dano ação de indenização ação regressiva e prescrição.",
"Antiguidade Oriental (Egípcios Mesopotâmicos Persas Indianos e Chineses)",
"Juros simples",
"Taxas de juros",
"Matrizes Determinantes e Sistemas Lineares",
"Matemática Financeira",
"Fisioterapia em Traumato-ortopedia e Reumatologia",
"Ortopedia e Traumatologia na Fisioterapia",
"Psicopatologia",
"Teclas de atalho",
"Mas-mais",
"Tabelas-Verdade",
"História e Geografia do Estado da Paraíba",
"Processos",
"Segurança Terrorismo Desarmamento Espionagem e Narcotráfico",
"Problemas Lógicos Com Dados Figuras e Palitos",
"Armamento Tiro e Defesa Pessoal",
"Marcenaria",
"Estruturas",
"Análise Estrutural",
"Geotecnia",
"Mecânica dos Solos",
"Geotécnica",
"Sondagem",
"Legislação do Estado de Santa Catarina",
"Lei nº 6.218 de 1983 - Estatuto dos Policiais Militares e Legislação Específica",
"Normas Brasileiras e Estrangeiras Aplicáveis à Saúde e Segurança",
"NR 6 - Norma Regulamentadora n° 6 - Equipamentos de Proteção Individual - EPI",
"Normas Regulamentadoras de Ministério do Trabalho e Emprego",
"Formação do imperativo",
"Normas Técnicas do Corpo de Bombeiros Militar do Estado de Santa Catarina",
"NBR 14.276 - Brigada de Incêndio",
"NR 23 - Norma Regulamentadora nº 23 - Proteção Contra Incêndio",
"Penalidades",
"Abastecimento de Água",
"Hematologia nas Técnicas em Laboratório",
"Técnicas Manuais e Automatizadas de Aparelhos e Materiais Para Fins de Diagnóstico Hematológico",
"Bioquímica em Biomedicina",
"Função Hepática",
"Preparação de Meios de Cultura Soluções Reagentes e Corantes",
"Licitações em Obras Públicas",
"Edificação",
"Materiais de Construção na Engenharia Civil",
"Telhados",
"Farmacovigilância",
"Temas Educacionais Pedagógicos",
"Tendências Pedagógicas",
"Eletrônica Analógica na Engenharia Eletrônica",
"Amplificadores em Engenharia Eletrônica",
"Sistemas de Controle",
"Diagrama de Blocos",
"Função de Transferência",
"Transformada de Laplace",
"Máquinas Elétricas",
"Máquina Síncrona",
"Legislação de Psicologia e Resoluções do Conselho Federal de Psicologia",
"Código de Ética Profissional do Psicólogo (Resolução CFP Nº 10/05)",
"Referência bibliográfica",
"Normas e Legislações da Biblioteconomia",
"Normas Internacionais e de Padronização",
"AACR-2",
"Circuitos Elétricos na Engenharia Elétrica",
"Circuitos CA",
"Instalações Elétricas em Engenharia Elétrica",
"Equipamentos em Baixa Tensão",
"Eletrônica Digital na Engenharia Eletrônica",
"Álgebra Booleana e Circuitos Lógicos em Engenharia Eletrônica",
"Filtros",
"Amp Op",
"Teoria Geral do Crime Militar",
"Noções Fundamentais de Direito Penal Militar",
"Aplicação da Lei Penal Militar",
"Mecânica dos Fluidos",
"Fundamentos e Análise da Cinemática de Escoamentos",
"Uroanálise e Outros Líquidos Corporais",
"Exames Laboratoriais – Automatizadas ou Técnicas Manuais",
"Função Endócrina",
"Síndrome do Desconforto Respiratório Agudo",
"Médico da Família",
"Saúde do Idoso na Atenção Básica",
"Fundação",
"Arquitetura na Engenharia Civil",
"Projetos de Arquitetura",
"Instrumentos e Métodos de Medição",
"Relações Pessoais",
"Ética e Postura do Servidor",
"Dentística",
"Restaurações Diretas e Indiretas",
"Planejamento e Controle de Obras",
"Planejamento e Gerenciamento de Obras",
"Orçamento no Planejamento e Controle de Obras na Engenharia Civil",
"Estradas e Transporte",
"Terraplenagem",
"Canteiro de Obras",
"Métodos Construtivos",
"Qualidade no setor público",
"O modelo de excelência adaptado à Gestão Pública",
"Organizações Sistemas e Métodos",
"Estrutura Organizacional na Administração Geral",
"Recrutamento e Seleção",
"Agregando Pessoas",
"Abordagem Neoclássica da Administração",
"Teoria Neoclássica da Administração",
"Planejamento Educacional",
"Tipos de Planos de Ensino",
"Distribuição na Engenharia Elétrica",
"Subestação",
"Análise de SEP",
"Estabilidade",
"Diodo na Engenharia Eletrônica",
"Transistor",
"Circuitos CC",
"Circuitos Trifásicos em Engenharia Elétrica",
"Máquina de Indução",
"Máquina de Corrente Contínua",
"Transformador de Potência",
"Sub-Redes",
"História da Educação Brasileira",
"A Educação como Processo de Construção Histórica",
"Automação em Bibliotecas",
"MARC21",
"Classificação Decimal Universal",
"Processos Técnicos",
"Catalogação",
"Comunicação Cartográfica",
"Geodésia Geométrica",
"Medidas Elétricas",
"Aterramentos",
"Modelagem Controle e Instrumentação de Processos Químicos",
"Arquitetura na Engenharia Eletrônica",
"Operações Unitárias",
"Balanço de Massa e Energia",
"Fenômenos de Transporte: Mecânica dos Fluidos Transferência de Calor e Transferência de Massa",
"Cinética e Cálculo de Reatores",
"Termodinâmica",
"Transferência de Calor (Condução Convecção e Radiação)",
"Engenharia de Áudio e Telecomunicações",
"Sistemas Embarcados",
"Automação Industrial",
"Programação Ladder",
"Análise por Índices",
"Índices de Endividamento (Estrutura de Capital)",
"Índices de Rentabilidade",
"Índices de Lucratividade",
"Política Nacional de Atenção Básica",
"Dermatologia",
"Gastroenterologia",
"Hidráulica na Engenharia Civil",
"Cirurgia Geral",
"Lei nº 13.979 de 2020 e Lei nº 14.035 de 2020 - Medidas para Enfrentamento da Emergência de Saúde Pública de Importância Internacional Decorrente do Coronavírus Responsável pelo Surto de 2019",
"Planejamento Estratégico Tático e Operacional",
"Agentes públicos e Lei 8.112 de 1990",
"Responsabilidades do servidor",
"Aspectos Técnicos e Burocráticos na Construção Civil",
"Revestimento",
"Gestão de Politicas Públicas",
"As políticas públicas no Estado brasileiro contemporâneo",
"Armazenagem Distribuição e Transporte de Materiais",
"Movimentação Armazenagem e Distribuição",
"Objeto e Obrigatoriedade da Licitação",
"Tipos e Modalidades – Concorrência tomada de preços convite concurso e leilão",
"Conceito Competência Legislativa Sujeitos e Finalidades",
"Ciclo de Gestão do Governo Federal",
"Agentes orçamentários",
"Aplicando Pessoas",
"Controle e Avaliação",
"Processo Decisório",
"Conceitos e Métodos do Processo Decisório",
"Hematologia Clínica",
"Psicoterapia",
"Transtornos Relacionados a Substâncias",
"Projeto e Orçamentação",
"Eletromagnetismo na Engenharia Elétrica",
"Materiais Elétricos",
"Lógica Binária e Hexadecimal",
"Conservação da Energia",
"Microcontroladores",
"Redes Industriais",
"Análise de Sistemas Lineares",
"Lei de Introdução às Normas do Direito Brasileiro (LINDB)",
"Onde-aonde",
"Contadores em Engenharia Eletrônica",
"Flip-flops",
"Diagrama de Bode",
"PLC",
"Ingressos e Dispêndios Públicos",
"Resultado Nominal e Primário",
"Alergia e Imunologia",
"Posicionamento Espacial",
"Treinamento e Desenvolvimento",
"Desenvolvendo Pessoas",
"Concepção Cartográfica na Engenharia de Agrimensura",
"Instrumentos e Métodos de Medição na Engenharia de Agrimensura",
"Concepção Cartográfica na Engenharia Cartográfica",
"Orçamento Público em AFO",
"Programação e Execução Financeira e Orçamentária",
"LC nº 101 de 2000 - Lei de Responsabilidade Fiscal",
"A Despesa Pública na Lei de Responsabilidade Fiscal",
"A Receita Pública na Lei de Responsabilidade Fiscal",
"Funções morfossintáticas da palavra SÓ",
"A fim de- afim",
"Índices de Liquidez",
"Força e Movimento",
"Eletricidade e Magnetismo",
"Gravidade Massa e Peso",
"Senão- se não",
"Tampouco- tão pouco",
"Elementos Químicos e Classificação Periódica",
"Energia Térmica Calor e Temperatura",
"Energia Cinética e Potencial e Transformações das Formas de Energia",
"Propriedades do Ar",
"Máquinas Simples",
"Espelhos e Lentes",
"Patologia Diagnóstico e Tratamento Radioterápico",
"Anatomia e Fisiologia Humana em Radiologia",
"Sistema Esquelético Articulações e Músculos",
"Equipamentos Radiológicos",
"Operação de Equipamentos e Posição Radiológica",
"Posição dos advérbios | Position of adverbs",
"Sistema Renal",
"Exames Radiológicos em Contraste Gerais e Especiais",
"Processamento de Imagens Radiológicas",
"Fundamentos de Dosimetria e Radiobiologia",
"Regra de Três Simples",
"Análise Custo-Volume-Lucro",
"Ponto de Equilíbrio Contábil Financeiro e Econômico",
"Índices de Atividade ou Rotatividade (Prazos Médios)",
"Manual de Redação da Presidência da República",
"Concordância Verbal e Nominal",
"Bioestatística em Veterinária",
"Custo de Produção",
"A Contabilidade do Custo de Produção",
"Custo de Transformação ou Conversão",
"DER - Diagrama de Entidade e Relacionamento",
"Estratégia organizacional",
"Margem de Contribuição",
"Doenças Gastrointestinais",
"Ensino da Língua Estrangeira Espanhola | Enseñanza y Aprendizaje del Español como Lengua Extranjera",
"Ensino da Língua Estrangeira Inglesa",
"Planejamento Familiar",
"Lucro: Bruto Operacional e Líquido",
"Critérios de Avaliação dos Bens Patrimoniais",
"Demonstração dos Fluxos de Caixa -DFC",
"Atribuições das Áreas de Gestão",
"Administração Financeira",
"Doenças Cardiovasculares",
"Indicadores de Mercado (Lucro por Ação Payback Dividend Yeld Payout etc)",
"Espécies de Crimes militares",
"Crimes Contra o Serviço Militar e o Dever Militar",
"Processo Penal Militar e sua Aplicação",
"Epidemiologia Veterinária",
"Classificação da Receita Orçamentária na Contabilidade Pública",
"Sistemas de Produção (Acumulação de Custos)",
"Produção por Ordem",
"Abordagem Estruturalista da Administração",
"Teoria Estruturalista da Administração",
"Despesa Pública",
"Classificação da Despesa Orçamentária em AFO",
"Estágios da Receita e Despesa",
"Estágios da Despesa Orçamentária",
"Gestão Pública Empreendedora ou Empreendedorismo governamental",
"Administração de Compras",
"Processo ou Ciclo de Compras",
"Administração da Produção",
"Missão Visão e Negócio",
"Liderança e Motivação",
"Balanced Scorecard na Administração Geral",
"Contabilidade - Noções Gerais",
"Contas: Plano Teoria e Classificação",
"Fontes de Informação",
"Capacidade de Produção e Limitações",
"Gestão Patrimonial",
"Dívida e Endividamento",
"Instrumentos Financeiros e Derivativos",
"Contabilidade de Custos - Noções Introdutórias",
"Contabilização dos Custos",
"Sistemas de Custeio",
"Custeio por Absorção ou Integral",
"Demonstração de Fluxo de Caixa - DFC",
"Intoxicação e Superdosagem",
"Hipertensão Arterial Sistêmica",
"Hematologia",
"Doenças Crônicas Não Transmissíveis: panorama geral e plano de enfrentamento",
"Infecções Sexualmente Transmissíveis (IST)",
"Saúde do Idoso",
"Pronúncia e Som | Pronunciation and Sound",
"Processo de Enfermagem e Sistematização da Assistência de Enfermagem",
"Exame Físico",
"Equinodermos Cefalocordados e Urocordados",
"Crimes Contra a Autoridade ou Disciplina Militar",
"Procedimentos",
"Balanço Financeiro - BF",
"Crimes Militares em Tempo de Paz",
"Crimes Militares em Tempo de Guerra",
"Indexação nos Processos Técnicos em Biblioteconomia",
"Receita  Pública",
"Estágios da Receita Orçamentária",
"Descrição",
"Demonstração das Variações Patrimoniais - DVP",
"Gerenciamento de Custos para Controle e Decisão",
"Formação do Preço de Venda",
"Ativo Intangível",
"Dívida Ativa",
"Custo Primário",
"Crimes Contra a Administração Militar e Contra a Administração da Justiça Militar",
"Administração de Estoques",
"Previsão e Custos dos Estoques",
"Participação Societária",
"Método da Equivalência Patrimonial - MEP",
"Otorrinolaringologia",
"Teorias e Práticas para o Ensino de Geografia",
"Classificação de Processos Técnicos em Biblioteconomia",
"Outras Demonstrações",
"Demonstrações das Mutações do Patrimônio Líquido - DMPL",
"Dívida Pública: Flutuante e Consolidada",
"Joint Venture (Empreendimento Controlado em Conjunto)",
"Noções Gerais de Balanço Patrimonial",
"Identidades Trigonométricas",
"Função Modular",
"Secante Cossecante e Cotangente e Ângulos Notáveis",
"Engenharia Aeronáutica",
"Teoria da Pena",
"Elementos Essenciais – Conduta Nexo Causal Culpa e Dano",
"Lei 6.880 de 1980 - Estatuto dos Militares",
"Personalidade Pessoa Natural e Capacidade",
"Ordem Econômica e Financeira",
"Sistema Tributário Nacional",
"Desconto Racional (Real ou Por Dentro) Composto",
"Lei Complementar 164 de 2006 - Estatuto dos Militares do Estado do Acre",
"Legislação do Estado do Acre",
"Economia Nacional na Atualidade",
"Desconto Racional Simples",
"Negação - Leis de Morgan (Negativa de uma Proposição Composta)",
"História e Geografia do Estado de Alagoas",
"Sistema Interamericano de Proteção aos Direitos Humanos:  Instrumentos Normativos",
"Convenção Americana sobre Direitos Humanos (Pacto de San José)",
"Inquérito Policial - Noções Gerais",
"Inquérito Policial - Características",
"Sistema Global de Proteção dos Direitos Humanos:  Instrumentos Normativos",
"Legislação do Estado de Alagoas",
"Decreto nº 37.042 de 1996 - Regulamento Disciplinar da Polícia Militar",
"Lei 3.437 de 1975 - Estatuto dos Policiais Civis do Estado de Alagoas",
"Pediatria e Neonatologia",
"História e Geografia do Estado do Amapá",
"História e Geografia do Estado do Amazonas",
"Endodontia",
"Patologias Endodônticas: Diagnóstico e Tratamento",
"Patologia oral e Maxilofacial",
"Periodontia",
"Radiologia",
"Saúde Bucal Coletiva",
"Preparo Cavitário",
"Emergências Psiquiátricas",
"Transtornos Mentais Orgânicos",
"Psicologia Aplicada à Odontologia",
"Manifestação Oral das Doenças Sistêmicas",
"Biossegurança. Vacinas.",
"Perícia Psiquiátrica",
"Aspectos de Ética na Pesquisa Ética Médica e Perícia Médica",
"Distúrbio Bipolar e Transtorno de Humor",
"Psicofarmacologia",
"Psiquiatria infantil",
"Emprego dos Pronomes de Tratamento",
"Polícia Comunitária",
"Ato infracional",
"Atos Probatórios",
"Atos administrativos",
"Atos administrativos em espécie",
"Direito de Família",
"União Estável",
"Direito das Coisas / Direitos Reais",
"Propriedade",
"Crimes Contra a Pessoa",
"Crimes omissivos",
"Denúncia e Queixa",
"Direito à Privacidade",
"Teoria dos Direitos Fundamentais",
"Objetivos Fundamentais da República",
"História e Geografia do Estado do Tocantins",
"História e Geografia do Estado do Ceará",
"Introdução Ética e Moral e Orientações Gerais",
"Histórico Reformas e Evolução da Administração Pública no Brasil",
"A crise do Estado",
"Mediação e Conciliação",
"Busca e apreensão",
"Sujeitos de Direito Internacional Público: Estados",
"Imunidade à Jurisdição Estatal: imunidade do estado estrangeiro  diplomacia e serviço consultar imunidade penal e renúncia à imunidade",
"Poder Legislativo",
"Câmara dos Deputados",
"Programa Nacional de Direitos Humanos (PNDH)",
"Segurança Pública Acesso à Justiça e Combate À Violência",
"Inadimplemento das Obrigações",
"Parentesco",
"Ação Penal Militar",
"Legítima defesa",
"Resultado",
"Vícios e suas consequências",
"Constituição do Estado do Ceará",
"Cooperação dolosamente distinta",
"Crimes contra a vida",
"Homicídio",
"Direito à não autoincriminação (nemo tenetur se detegere)",
"Quantificadores",
"Declaração dos Princípios Básicos de Justiça Relativos às Vítimas da Criminalidade e de Abuso de Poder",
"Lei Nº 7.289 de 1984 – Estatuto dos Policiais Militares da Polícia Militar do Distrito Federal e Legislação Específica",
"Medidas Pertinentes aos Pais ou Responsáveis",
"Conselho Tutelar",
"Transmissão digital",
"Sistemas de Transmissão e Modulação| Redes de Acesso e Infra-estrutura",
"Higiene nos Procedimentos de Enfermagem",
"Conceito e classificação dos atos administrativos",
"Teoria dos motivos determinantes",
"História e Geografia de Brasília - Distrito Federal",
"Lei nº 6.450 de 1977 - Organização Básica da Polícia Militar do Distrito Federal",
"Lei dos Crimes de Tortura – Lei nº 9.455 de 1997",
"Das Partes e Procuradores",
"Sujeitos da relação processual",
"Posse - Teoria Classificação e Aquisição",
"Juiz Auxiliares e Partes no Processo Penal Militar",
"Encerramento do Inquérito Policial",
"Cargo emprego função",
"Contratos Administrativos",
"Cláusulas Exorbitantes e Equilíbrio Econômico-Financeiro",
"BrOffice.org e LibreOffice Calc",
"Microsoft Excel",
"Sistemas de Comunicação Via Rádio",
"Exames Laboratoriais",
"Processo Legislativo",
"Espécies Normativas: Medida Provisória Lei Delegada Decreto Legislativo e Resolução",
"Acumulação de cargos e funções",
"Código de Ética dos Profissionais de Administração",
"Visão Geral da área de RH na organização",
"O papel da Área de Recursos Humanos",
"Embargos infringentes",
"Apelação no CPC 1973",
"Da Intervenção de terceiros",
"Assistência no  CPC 1973",
"Respostas do réu  - Exceção",
"Pressupostos Processuais",
"Imparcialidade do Juiz e Competência do Juízo",
"Inadmissibilidade das provas obtidas por meios ilícitos",
"Convenção Internacional sobre Eliminação de Todas as Formas de Discriminação Racial",
"Direito Internacional dos Direitos Humanos",
"Direitos humanos direito humanitário e direito dos refugiados",
"Desconcentração e Descentralização Administrativa",
"Princípios da Administração Pública",
"Verdades e Mentiras",
"Principais Instituições Públicas de Direitos Humanos",
"Conselho de Direitos Humanos (antigo CDDPH)",
"Espectro",
"Classificação das Normas Constitucionais",
"Critérios Legais do Crime Militar",
"Periclitação da vida e da saúde",
"Abandono de incapaz",
"Pacto Internacional de Direitos Civis e Políticos",
"Abordagem Comportamental da Administração",
"Teoria Comportamental da Administração/Teoria do Desenvolvimento Organizacional (DO)",
"Sistema Interamericano de Proteção aos Direitos Humanos: Instituições",
"Comissão Interamericana de Direitos Humanos",
"Corte Interamericana de Direitos Humanos",
"Conhecimentos Gerais de Educação nas Questões Sociais",
"Histórico",
"Montante",
"Histologia",
"Esquizofrenia e outros Transtornos Psicóticos",
"Psicoterapias",
"Psicologia do Desenvolvimento",
"Teorias e Técnicas Psicoterápicas",
"Ataques",
"Memória",
"Processadores",
"Suporte Ventilatório Mecânico",
"Ortopedia e Traumatologia",
"Distúrbios Nutricionais",
"Obesidade e Síndrome Metabólica",
"Parasitologia na Odontologia",
"Propedêutica",
"Cariologia",
"Diagnóstico Clínico e Radiográfico da Cárie",
"Odontopediatria",
"Odontogênese",
"Transtornos de Personalidade",
"Transtornos do Humor",
"Transtornos Dissociativos",
"Arquiteturas",
"Conceitos Básicos de Correio Eletrônico",
"Medicina do trabalho",
"Sistema de Saúde Brasileira",
"Legislação Previdenciária (Previdência Social e Lei nº 8.213/91)",
"Lesões da Mucosa Oral",
"Lesões Não-Cariosas",
"Anatomia da Cavidade Pulpar",
"Prótese",
"Prótese Parcial Fixa",
"Direito à Liberdade",
"Direito de Propriedade",
"Poder Constituinte Originário Derivado e Decorrente - Reforma (Emendas e Revisão) e Mutação da Constituição",
"Gestão de Documentos / Arquivos Corrente e Intermediário",
"Organização de Arquivos / Métodos de Arquivamento",
"Programa Nacional de Direitos Humanos",
"Cessão-sessão/secção/seção",
"Lei de Armas (Estatuto do Desarmamento) – Lei nº 10.826 de 2003 e Decretos Regulamentares",
"Supremo Tribunal Federal",
"Poder Executivo",
"Organização do Poder Judiciário",
"Imunidade Crimes Comuns Crimes de Responsabilidade (Lei nº 1.079 de 1950) e Impeachment",
"Farmácia Hospitalar e Comunitária",
"Cálculos Farmacêuticos",
"Teorias e Práticas para o Ensino de Matemática",
"Educação Infantil",
"Alfabetização e Letramento",
"Normas Educacionais dos Estados",
"Teorias e Práticas para o Ensino de Língua Portuguesa",
"Circuitos",
"Lei Maria da Penha - Lei nº 11.340 de 2006",
"Poder vinculado e discricionário",
"Comunicação no Atendimento ao Público",
"Processo de Comunicação",
"Causas de extinção da punibilidade",
"Causas de Extinção da Punibilidade - Noções gerais",
"Comportamento de Pessoas nas Organizações",
"Relacionamento interpessoal",
"Ortodontia e Ortopedia Facial",
"Legislação em Saúde",
"Diagnósticos de Enfermagem - NIC-NOC/CIPE/NANDA",
"Surgimento e Institucionalização do Serviço Social",
"Fundamentos históricos e teórico-metodológicos do Serviço Social",
"Decreto nº 7.508 de 2011",
"Microsoft Excel 2010",
"História e Geografia do Estado do Piauí",
"Mundo Árabe : de Maomé ao Império",
"Conceito de Criança e Adolescente e Prioridades",
"Fisioterapia Respiratória",
"Foucault",
"Clínica Médica Veterinária",
"Clínica Médica para Pequenos Animais",
"Farmacologia Veterinária",
"Intoxicações Pesticidas e Envenenamento",
"Legislação da Educação",
"Educação Especial - Leis Tratados e Normas Especiais",
"Inclusão e Exclusão - diversidade desigualdade e diferença",
"Multiculturalismo e Educação",
"História e Geografia do Estado do Rio de Janeiro",
"Teorias Criminológicas: Escola de Chicago - explicação ecológica do crime Estrutural-funcionalistas Associação Diferencial Anomia Subcultura Delinquente Crítica ou Radical Etiquetamento ou “Labelling Approach”.",
"Psicodinâmica do Trabalho",
"Psicologia e Organizações",
"Psicologia Jurídica",
"Psicologia Criminal e Carcerária",
"Freud",
"Teorias da Personalidade",
"Avaliação Educacional",
"Avaliação e Indicadores de Qualidade na Educação",
"Psicologia Comunitária",
"Dinâmicas de Grupo",
"Grupos e Equipes",
"Avaliação Sistêmica: SAEB ANEB Prova Brasil ANA",
"Saúde da Criança na Atenção Básica",
"Psicologia da Saúde",
"Dor Estresse e Luto",
"Genética Médica",
"Resolução CFP n° 006/2019 - Manual de Elaboração de Documentos Escritos - Informes e Documentos Psicológicos",
"Terapia Cognitivo Comportamental",
"História e Geografia do Estado do Rio Grande do Norte",
"Mastologia",
"Doença reumatológica",
"Doenças do Colágeno",
"Artrite Reumática",
"Bases Etiológicas de condição clínica",
"Psicologia Hospitalar",
"Farmacologia",
"Farmacotécnica e Tecnologia Farmacêutica",
"Farmacologia e Pesquisa Clínica",
"Controle de Qualidade Industrial",
"Diagnósticos",
"Patologia Clínica",
"Hipóteses Diagnósticas",
"Estrito cumprimento de dever legal e exercício regular de direito",
"Legislação do Estado de Rondônia",
"Decreto-Lei nº 09-A de 1982 - Estatuto dos Policiais Militares da Polícia Militar do Estado de Rondônia",
"Decreto nº 13.255 de 2007 - Regulamento Disciplinar da Polícia Militar de Rondônia",
"Categorias e gerações dos direitos humanos",
"Concepções doutrinárias sobre a natureza dos direitos humanos",
"Direitos da Nacionalidade",
"Princípios de Direito Constitucional Internacional",
"História e Geografia do Estado de Rondônia",
"Classificação dos Crimes Militares",
"Crime impossível",
"Características dos direitos humanos",
"Evolução histórica dos direitos humanos e Declaração dos Direitos do Homem e do Cidadão",
"Teoria das nulidades",
"Requisitos do ato administrativo – competência finalidade forma motivo e objeto",
"Crimes Contra a Incolumidade Pública",
"Concurso de Agentes",
"Ação penal privada propriamente dita ou exclusiva",
"Legislação do Estado de Roraima",
"Lei Complementar Nº 194 de 2012 – Estatuto dos Militares do Estado de Roraima",
"Procedimento especial dos crimes de competência do Tribunal do Júri",
"Ao encontro- de encontro",
"Legislação do Estado de São Paulo",
"Lei Complementar Nº 893 de 2001 - Regulamento Disciplinar da Polícia Militar e Legislação Específica",
"Transtornos de Ansiedade Fobias e Síndrome do Pânico",
"Sistemas Termomecânicos",
"Turbinas a Vapor (elementos características e classificação)",
"Leis e Propriedades da Termodinâmica",
"C++ (mais mais)",
"Eletrônica para Engenharia de Automação",
"Eletrônica Digital para Engenharia de Automação",
"Análise e Controle de Sistemas",
"Diagramas de Bloco e de Bode",
"Estática dos Fluidos",
"Algoritmos",
"Lógicas de Programação",
"Função de Transferência para Engenharia de Automação",
"Conceitos Básicos e Algoritmos",
"Estrutura de Dados",
"Eletrônica Analógica para Engenharia de Automação",
"Estruturas de Concreto",
"Patrimônio Líquido",
"Componentes Eletrônicos para Engenharia de Automação",
"Eletrônica de Potência para Engenharia de Automação",
"Robótica e Automação",
"Automação Industrial para Engenharia de Automação",
"Modelagem de Sistemas Dinâmicos",
"Fotogrametria Digital",
"Geodésia Espacial",
"Padrões de projeto (Design Patterns)",
"Conceitos Básicos de Estrutura de Dados",
"Planejamento Monitoramento e Avaliação de Planos Programas e Projetos",
"Roteadores",
"Conceitos Básicos em Arquitetura de Computadores",
"VLAN",
"Árvores",
"Climatologia",
"Proteção Social à Criança ao Adolescente e à Família",
"Bolsa Família",
"Matricialidade Sociofamiliar",
"Proteção à Mulher à Criança e ao Adolescente - Proteção a Família",
"Serviço Social e Minorias: grupos étnicos/raciais movimentos sociais questão agrária e ambiental questão habitacional questão de gênero e população de rua",
"Conceito e Características",
"Duração Extinção Inexecução Sanções e Responsabilidade",
"Álgebra relacional",
"PHP",
"UML",
"O processo de socialização",
"Cidadania e movimentos sociais",
"Movimentos sociais no Brasil",
"Os Pré-Socráticos",
"Meio ambiente e sociedade",
"A questão ambiental",
"Crise dos anos 70 e Desmonte do “Welfare State”",
"O que é a Filosofia",
"O Fazer Filosófico",
"Filosofia e Conhecimento",
"Atualidades do ano de 2022",
"Atualidades do ano de 2023",
"Democracia Direta Indireta ou Representativa Semidireta e Participativa. Democracia Liberal Teoria das Elites e Democracia Majoritária e Consensual",
"Poliarquia Democracia Pluralista Deliberativa e Teorias Democráticas Contemporâneas. Sistema Representativo Sistemas Eleitorais e Partidos Políticos. Grupos de Pressão e Opinião Pública",
"Sensoriamento Remoto e Geoprocessamento",
"Mudanças na indústria: Fordismo e Taylorismo",
"Sociedade civil",
"Cônicas",
"A Política Grega (A República de Platão e a Política de Aristóteles)",
"A Consciência e os Limites do Conhecimento",
"Violência e conflitos urbanos",
"Natureza e sociedade",
"Calor Sensível",
"Processo de urbanização",
"Instituições sociais normas e valores",
"Direitos civis políticos e sociais",
"Max Weber e a Ação Social",
"Socialização primária: família",
"Sociologia Jurídica",
"Lógica: Nascimento e seus Elementos",
"Reconstrução da democracia e suas crises",
"Modalidades Esportivas",
"Inequação Logarítmica",
"Educação Física no Ambiente Escolar",
"Relação Educação Física Escolar e Esporte",
"Práticas e Mediações Educacionais em Artes",
"Estado de Natureza – Estado",
"Avaliação de Alternativas de Investimento",
"Ontologia e a Natureza do ser",
"Estética e Crítica das Artes",
"Projetos em Artes Visuais",
"A Origem da Filosofia",
"Pensamento pós-moderno",
"Algoritmo",
"Formas de Governo: Democracia e Representatividade"

]

export default assuntos;