import React from 'react';
import '../css/computador/SobreMim.css';

const SobreSite = () => {
  return (
    <div className="sobre-mim-container">
      <div className="sobre-mim-content">
      
        <img src="https://lh3.googleusercontent.com/a/AEdFTp6heSi-9hKzY5jjVcjl2-6PF2UKSmGll__sJoAkAw=s96-c" alt="Minha Foto" className="sobre-mim-foto" />
        <p>
          Bem-vindo ao meu site de questões! Tenho uma paixão enorme por ajudar as pessoas a atingirem seus objetivos acadêmicos,
          especialmente quando se trata de passar no vestibular. Com mais de 150 mil questões e gabaritos, este site é um recurso
          valioso para todos os estudantes.
        </p>
        <p>
          Cada questão foi cuidadosamente selecionada e revisada para garantir que você tenha a melhor preparação possível. Além
          disso, o gabarito de cada questão ajuda a esclarecer dúvidas e a entender melhor cada matéria.
        </p>
        <p>
          Acredito que a educação é a chave para um futuro melhor, e estou comprometido em fornecer as ferramentas e recursos
          necessários para que aqueles que não podem pagar um cursinho tenha acesso a um conteúdo de qualidade. O site ainda tem muitas
          adições a serem feitas e logo logo vamos ter todas as funcionalidades propostas, como correção de redação, geração de simulados,
          relatório de desempenho, ranking para você ver seu progresso em relação ao outros estudantes, comentar nas questões e muito mais. Aproveite ao máximo este site e boa sorte nos seus estudos!
        </p>
        <p>  Seu feedback é muito importante para uma melhoria contínua no site. Qualquer erro em questão/feedback construtivo mande para 
        <a href="mailto:vestibaquest20@gmail.com"> vestibaquest20@gmail.com</a>.</p>
      </div>
    </div>
  );
};
  
export default SobreSite;
