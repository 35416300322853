import React from 'react';
import '../css/computador/Login.css';
import image from '../imagens/logo3.png';
import google from '../imagens/google-logo.svg';
import host from '../constantes';
import {useUser} from '../hooks/UserProvider';

const Login = () => {
  const handleLogin = () => {
    window.location.href = `${host}login`;
  };

  const user = useUser();
  if (user !== 401 && user !== null && user !== undefined) { 
    window.location.href = '/home';
  }

  return (
    <div className="container-login">
      <div className="login">
        <div className="imagem-container">
          <img src={image} alt="Logo" />
        </div>
        <h1>Bem-vindo!</h1>
        <button onClick={handleLogin} className="google-login-button">
          <img src={google} alt="Google Logo" />
          <span>Login com Google</span>
        </button>
      </div>
    </div>
  );
};

export default Login;
