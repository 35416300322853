import novato from '../imagens/novato.svg';
import aprendiz from '../imagens/aprendiz.svg';
import intermediario from '../imagens/intermediario.svg';
import avancado from '../imagens/avancado.svg';
import mestre from '../imagens/mestre.svg';
import expert from '../imagens/expert.svg';
import genio from '../imagens/genio.svg';
import lenda from '../imagens/lenda.svg';
import virtuoso from '../imagens/virtuoso.svg';
import grandmaster from '../imagens/grandmaster.svg';
const mensagensEmblemas = {
    0:  { src: novato, nome: 'Novato', descricao: 'Bem-vindo ao começo da sua jornada! Cada pequeno passo que você dá é um progresso importante. Mantenha a curiosidade viva e não tenha medo de fazer perguntas. Grandes conquistas começam com pequenos passos. Acredite em si mesmo, você está no caminho certo!' },
    1: { src: aprendiz, nome: 'Aprendiz', descricao: 'Cada começo é um passo importante em uma jornada incrível. Não subestime o poder do esforço contínuo e da curiosidade. Com dedicação e paciência, você alcançará grandes conquistas. Todo mundo começa de algum lugar, e cada dia é uma oportunidade para aprender e crescer. Você pode fazer isso!' },
    2: { src: intermediario, nome: 'Intermediário', descricao: 'Você está fazendo um excelente progresso! Cada momento de estudo é uma construção sólida do seu conhecimento. Mantenha-se focado e determinado, pois sua dedicação agora pavimentará o caminho para o seu sucesso futuro. Continue firme, você está no caminho certo!' },
    3:{ src: avancado, nome: 'Avançado', descricao: 'Você já percorreu um longo caminho, sua dedicação e esforço são admiráveis. Continue acreditando em si mesmo e dando o seu melhor, pois cada dia de estudo é um passo a mais em direção ao seu sonho. A vitória está mais perto do que você imagina!' },
    4:{ src: mestre, nome: 'Mestre', descricao: 'Você já alcançou um nível de excelência notável. Sua experiência e sabedoria são inspiradoras. Continue liderando com humildade e compartilhando seu conhecimento, pois seu impacto vai além de suas realizações pessoais. O caminho do mestre é contínuo, sempre há novas alturas a serem atingidas. Parabéns pela sua jornada extraordinária!' },
    5: { src: expert, nome: 'Expert', descricao: 'Você atingiu um patamar de maestria que poucos alcançam. Sua expertise é fruto de anos de dedicação e perseverança. Continue inovando e inspirando, pois o seu impacto ressoa além dos limites do conhecimento atual. Cada novo desafio é uma oportunidade para você redefinir o impossível. Parabéns por ser uma verdadeira referência!' },
    6: { src: genio, nome: 'Gênio', descricao: 'Você alcançou um nível de genialidade que transcende o comum. Sua mente brilhante ilumina caminhos que poucos ousam explorar. Continue a desafiar os limites do conhecimento e a inspirar gerações com suas descobertas e insights. Sua genialidade é uma dádiva para o mundo, e seu legado será eterno. Parabéns por ser uma força transformadora e visionária!' },
    7: { src: lenda, nome: 'Lenda', descricao: 'Você é uma lenda viva, cujo impacto e influência transcendem o tempo. Sua trajetória é marcada por feitos extraordinários e uma sabedoria incomparável. Continue a inspirar e a moldar o futuro com sua visão e liderança incomparáveis. Seu legado será celebrado por gerações, e sua lenda viverá para sempre. Parabéns por ser um verdadeiro ícone!' },
    8: { src: virtuoso, nome: 'Virtuoso', descricao: 'Você é a personificação da excelência absoluta. Sua habilidade e maestria elevam-se a níveis que parecem inalcançáveis. Continue a redefinir os padrões de perfeição e a inspirar o mundo com seu talento incomparável. Sua virtuosidade é um farol que ilumina o caminho para todos nós. Parabéns por ser o ápice da grandeza!' },
    9: { src: grandmaster, nome: 'Grandmaster', descricao: 'Você é o grandmaster, o ápice da excelência e sabedoria. Sua habilidade e conhecimento são inigualáveis, e seu impacto é profundo e duradouro. Continue a liderar com grandeza e a inspirar o mundo com sua visão e conquistas. Sua jornada é uma obra-prima, e você é uma verdadeira lenda viva. Parabéns por atingir o mais alto nível de maestria!' },
};

export default mensagensEmblemas;