import React, { createContext, useContext, useState, useEffect } from 'react';
import host from '../constantes';
import axios from 'axios';
const InfoHomeContext = createContext();


export const useInfoHome = () => {
    return useContext(InfoHomeContext);
};

export const InfoHomeProvider = ({ children }) => {
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
   
    useEffect(() => {
        const fetchInfo = async () => {
            if (host === 'http://localhost:5001/') {
                return;
            }
            try {
                const response = await fetch(host + 'api/v1/info-home', {
                    credentials: 'include',
                    origin: 'https://vestibaquest.com.br',
                    headers: {
                        'Content-Type': 'application/json',
                        'origin': 'https://vestibaquest.com.br',
                    },
                });
                const data  = await response.json();
              
                setInfo(data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchInfo();
    }, []);

    return (
        <InfoHomeContext.Provider value={{ info, loading, error }}>
            {children}
        </InfoHomeContext.Provider>
    );
};
