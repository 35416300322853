import '../css/computador/Redacoes.css';
const Redacoes = () => {

    // const [redacoes, setRedacoes] = useState([]);
    // const [loading, setLoading] = useState(true);
    
    // useEffect(() => {
    //     const fetchRedacoes = async () => {
    //     const response = await fetch('http://localhost:5000/redacoes');
    //     const data = await response.json();
    //     setRedacoes(data);
    //     setLoading(false);
    //     }
    //     fetchRedacoes();
    // }, []);
    
    return (
        <div className='redacoes-container' style={{margin:"0 auto"}}>
        <h1>Redações</h1>
        <p>Aqui você encontrará análises de redações que alcançam notas maiores que 800 no ENEM. Aproveite para pegar todos os bizus.  </p>
        <div style={{margin:"auto", display:"flex", flexDirection:"column",alignContent:"center", alignItems:"center", justifyContent:"center", height:"45vh"}}>
        <h1>Em breve...</h1>
            
        <h2>Acesse a página de <a style={{textDecoration:"none"}} href="/questoes" >questões</a> </h2>
        </div>
        </div>
    );
    }

export default Redacoes;